/*-----------------------------------------------------------------------------------

    Template Name: Sample - Corporate Business Bootstrap4 HTML5 Template
    Template URI: site.com
    Description: Sample - Corporate Business Bootstrap4 HTML5 Template
    Author: MD THAHERIL ISLAM
    Author URI: site.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme default CSS
	02. Header
    03. Hero
	04. Footer

-----------------------------------------------------------------------------------*/
/*===========================
    1. COMMON css 
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
html,
body {
  height: 100%;
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #777777;
  line-height: 25px;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
  color: #000;
}
a:hover {
  color: #f63a3a;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #000;
  margin: 0px 0 15px;
  line-height: normal;
}

.binduz-er-title {
  text-transform: capitalize;
}

p {
  margin: 0 0 15px;
}

.slick-slide {
  outline: 0;
}

.page-link:focus {
  box-shadow: none;
}

.binduz-er-bg-cover {
  position: absolute;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.slick-slide img {
  display: inline-block;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
}

.gray-bg.bg-2 {
  background: #f8f8f8;
}

/*===== All Button Style =====*/
.binduz-er-main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #12238c;
  padding: 0 48px;
  font-size: 14px;
  line-height: 50px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #12238c;
}
.binduz-er-main-btn:hover {
  background-color: #f63a3a;
  color: #fff;
  border-color: #f63a3a;
}
.binduz-er-main-btn.binduz-er-main-btn-2 {
  background-color: #fff;
  color: #f63a3a;
  border-color: #f63a3a;
}
.binduz-er-main-btn.binduz-er-main-btn-2:hover {
  background-color: #f63a3a;
  border-color: #f63a3a;
  color: #fff;
}

/*----------------------------------------
   offcanvas menu
----------------------------------------*/
.binduz-er-news-off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #232323;
  top: 0;
}
.binduz-er-news-off_canvars_overlay.binduz-er-news-active {
  opacity: 0.5;
  visibility: visible;
}

.binduz-er-news-offcanvas_menu.binduz-er-news-offcanvas_menu_left
  .binduz-er-news-offcanvas_menu_wrapper {
  left: 0;
  right: auto;
  margin-right: 0;
  margin-left: -310px;
}
.binduz-er-news-offcanvas_menu.binduz-er-news-offcanvas_menu_left
  .binduz-er-news-offcanvas_menu_wrapper.binduz-er-news-active {
  margin-left: 0;
}

.binduz-er-news-offcanvas_menu_wrapper {
  width: 310px;
  position: fixed;
  background: #fff;
  z-index: 9999;
  top: 0;
  height: 100vh;
  transition: 0.5s;
  right: 0;
  margin-right: -310px;
  padding: 50px 15px 30px;
  overflow-y: auto;
}
.binduz-er-news-offcanvas_menu_wrapper.binduz-er-news-active {
  margin-right: 0;
}
.binduz-er-news-offcanvas_menu_wrapper .binduz-er-news-header-btn {
  margin-bottom: 30px;
}
.binduz-er-news-offcanvas_menu_wrapper .binduz-er-news-header-btn a {
  color: #000;
}
.binduz-er-news-offcanvas_menu_wrapper .binduz-er-news-header-btn a:hover {
  color: #000;
}
.binduz-er-news-offcanvas_menu_wrapper .binduz-er-news-header-social {
  padding-bottom: 30px;
}
.binduz-er-news-offcanvas_menu_wrapper .binduz-er-news-header-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-news-offcanvas_menu_wrapper .binduz-er-news-header-social ul li {
  display: inline-block;
}
.binduz-er-news-offcanvas_menu_wrapper .binduz-er-news-header-social ul li a {
  font-size: 14px;
  margin: 0 5px;
  color: #777777;
}

.binduz-er-news-offcanvas_main_menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-news-offcanvas_main_menu li {
  position: relative;
}
.binduz-er-news-offcanvas_main_menu li:last-child {
  margin: 0;
}
.binduz-er-news-offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.binduz-er-news-offcanvas_main_menu li a {
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #777777;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-news-offcanvas_main_menu li a:hover {
  color: #f63a3a;
}
.binduz-er-news-offcanvas_main_menu li ul {
  list-style-type: none;
}
.binduz-er-news-offcanvas_main_menu li ul.binduz-er-news-sub-menu {
  padding-left: 20px;
  list-style-type: none;
}

.binduz-er-news-offcanvas_footer {
  margin-top: 30px;
  padding-bottom: 50px;
  text-align: left;
}
.binduz-er-news-offcanvas_footer p {
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 24px;
}
.binduz-er-news-offcanvas_footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-news-offcanvas_footer ul li {
  font-size: 14px;
  line-height: 30px;
  padding-bottom: 15px;
  position: relative;
  padding-left: 24px;
}
.binduz-er-news-offcanvas_footer ul li i {
  color: #f63a3a;
  position: absolute;
  left: 0;
  top: 7px;
}

.binduz-er-news-canvas_close {
  position: absolute;
  top: 10px;
  right: 13px;
}
.binduz-er-news-canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 32px;
  height: 32px;
  display: block;
  text-align: center;
  line-height: 32px;
  border: 1px solid #ededed;
  border-radius: 50%;
  color: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-news-canvas_close a:hover {
  background: #f63a3a;
  border-color: #f63a3a;
  color: #fff;
}

.binduz-er-news-canvas_open a {
  text-align: center;
}

/*===========================
    SEARCH css 
===========================*/
.binduz-er-news-search-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999999;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.6s ease-out 0s;
  -moz-transition: all 0.6s ease-out 0s;
  -ms-transition: all 0.6s ease-out 0s;
  -o-transition: all 0.6s ease-out 0s;
  transition: all 0.6s ease-out 0s;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}
.binduz-er-news-search-box
  .binduz-er-news-search-header
  .binduz-er-news-search-title {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.binduz-er-news-search-box
  .binduz-er-news-search-header
  .binduz-er-news-search-close
  button {
  background: none;
  border: 0;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding-right: 35px;
  position: relative;
}
.binduz-er-news-search-box
  .binduz-er-news-search-header
  .binduz-er-news-search-close
  button
  span {
  width: 21px;
  height: 2px;
  background-color: #fff;
  display: block;
  position: absolute;
  right: 0;
}
.binduz-er-news-search-box
  .binduz-er-news-search-header
  .binduz-er-news-search-close
  button
  span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.binduz-er-news-search-box
  .binduz-er-news-search-header
  .binduz-er-news-search-close
  button
  span:nth-child(2) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 13px;
}
.binduz-er-news-search-box .binduz-er-news-search-body {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.binduz-er-news-search-box
  .binduz-er-news-search-body
  .binduz-er-news-search-form {
  position: relative;
}
.binduz-er-news-search-box
  .binduz-er-news-search-body
  .binduz-er-news-search-form
  input {
  width: 100%;
  border: 0;
  height: 80px;
  padding-left: 30px;
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 20px;
  color: #707070;
}
.binduz-er-news-search-box
  .binduz-er-news-search-body
  .binduz-er-news-search-form
  input::placeholder {
  color: #707070;
  opacity: 1;
}
.binduz-er-news-search-box
  .binduz-er-news-search-body
  .binduz-er-news-search-form
  button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  font-size: 36px;
  color: #707070;
  background: none;
  border: 0;
}
.binduz-er-news-search-box .binduz-er-news-search-footer {
  padding-bottom: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.binduz-er-news-search-box
  .binduz-er-news-search-footer
  .binduz-er-news-search-footer-content
  h4 {
  color: #707070;
  font-size: 24px;
}
.binduz-er-news-search-box
  .binduz-er-news-search-footer
  .binduz-er-news-search-footer-content
  p {
  color: #000;
  font-size: 16px;
}

.binduz-er-news-search-box.open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.binduz-er-newsr-white-popup {
  position: relative;
  background: #fff;
  padding: 30px;
  width: auto;
  max-width: 800px;
  margin: 20px auto;
}
.binduz-er-newsr-white-popup iframe {
  width: 100%;
  height: 400px;
}

/*===========================
    2.HEADER css 
===========================*/
.binduz-er-top-header-area {
  padding: 15px 0;
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-top-header-area {
    display: none;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-header-area {
    display: none;
  }
}
.binduz-er-top-header-area .binduz-er-bg-cover {
  left: 0;
  top: 0;
  background-image: url(../images/top-bar-bg.jpg);
}

.binduz-er-topbar-headline {
  margin-left: 85px;
}
.binduz-er-topbar-headline p {
  margin: 0;
  color: #fff;
}
.binduz-er-topbar-headline p span {
  font-weight: 700;
  text-decoration: underline;
}
.binduz-er-topbar-headline p span i {
  padding-right: 5px;
}
.binduz-er-topbar-headline p a {
  color: #fff;
}
.binduz-er-topbar-headline .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  background: #01409e;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 9;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-topbar-headline .slick-arrow:hover {
  background: #f63a3a;
}
.binduz-er-topbar-headline .slick-arrow.prev {
  left: -85px;
}
.binduz-er-topbar-headline .slick-arrow.next {
  left: -48px;
}

.binduz-er-topbar-social .binduz-er-news-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-topbar-social .binduz-er-news-social ul li {
  display: inline-block;
}
.binduz-er-topbar-social .binduz-er-news-social ul li a {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  margin-left: 10px;
}
.binduz-er-topbar-social .binduz-er-news-social ul li a:hover {
  color: #f63a3a;
}
.binduz-er-topbar-social .binduz-er-news-subscribe-btn {
  margin-left: 30px;
}

.binduz-er-news-top-header-area-2 {
  background: #004e87;
  padding: 22px 0;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-btns ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-btns ul li {
  display: inline-block;
  color: #fff;
  margin-right: 40px;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-btns ul li span {
  font-weight: 700;
  cursor: pointer;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-btns ul li span i {
  padding-right: 5px;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-btns ul li a {
  color: #fff;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-weather {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .binduz-er-news-top-header-area-2 .binduz-er-news-top-header-weather {
    display: none;
  }
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-weather ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-weather ul li {
  display: inline-block;
  margin-left: 40px;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-weather ul li a {
  color: #fff;
}
.binduz-er-news-top-header-area-2 .binduz-er-news-top-header-weather ul li a i {
  padding-right: 6px;
}

.binduz-er-header-meddle-bar {
  background: #fff;
  padding: 60px 75px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-header-meddle-bar {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-header-meddle-bar {
    padding: 20px;
  }
}
.binduz-er-header-meddle-bar .logo {
  margin-right: 100px;
}
@media (max-width: 767px) {
  .binduz-er-header-meddle-bar .header-add {
    display: none;
  }
}

.binduz-er-top-header-area-4 {
  position: relative;
  background: #fff;
  z-index: 100;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang .nice-select {
  background: #e74d58;
  color: #fff;
  border: 0;
  border-radius: 0;
  font-weight: 500;
  text-transform: capitalize;
  height: 60px;
  width: 140px;
  text-align: center !important;
  padding: 0;
  padding-top: 10px;
  margin-right: 50px;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang .nice-select::after {
  border-bottom-color: #fff;
  border-right-color: #fff;
  right: 35px;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang .nice-select ul {
  width: 100%;
  top: 95%;
  border-radius: 0;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang .nice-select ul li {
  color: #777777;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-weather ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-weather ul li {
  display: inline-block;
  line-height: 60px;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-weather ul li a {
  color: #999;
  margin-right: 20px;
  display: inline-block;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-weather ul li a i {
  color: #f63a3a;
  padding-right: 6px;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline {
  text-align: right;
  padding-right: 95px;
  margin-left: 0;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline p {
  color: #777777;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline p span {
  color: #000;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline p a {
  color: #777777;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline .slick-arrow {
  border-radius: 0;
  background: #ffeeef;
  color: #f63a3a;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline .slick-arrow:hover {
  background: #f63a3a;
  color: #fff;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline .slick-arrow.prev {
  left: auto;
  right: 35px;
}
.binduz-er-top-header-area-4 .binduz-er-topbar-headline .slick-arrow.next {
  left: auto;
  right: 0;
}

/*===========================
    2.HEADER css 
===========================*/
.binduz-er-header-area {
  position: relative;
  z-index: 99;
}
.binduz-er-header-area .navigation .navbar .navbar-brand {
  width: 280px;
  margin: 0;
  padding: 0;
}
.binduz-er-header-area.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  animation: sticky 1.2s;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}
.binduz-er-header-area.binduz-er-header-area-2 .binduz-er-header-nav {
  padding: 0;
  box-shadow: none;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation {
  background: #004e87;
  padding: 0 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-header-area.binduz-er-header-area-2
    .binduz-er-header-nav
    .navigation {
    padding: 0 20px;
  }
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item.active
  > a,
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item:hover
  > a {
  color: #fff;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-header-area.binduz-er-header-area-2
    .binduz-er-header-nav
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    > a {
    margin: 0 18px;
  }
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item:first-child
  > a {
  margin-left: 0;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation
  .navbar
  .navbar-nav
  .nav-item:last-child
  > a {
  margin-right: 0;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation
  .navbar
  .binduz-er-navbar-btn {
  width: 62%;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-header-area.binduz-er-header-area-2
    .binduz-er-header-nav
    .navigation
    .navbar
    .binduz-er-navbar-btn {
    width: 65%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-header-area.binduz-er-header-area-2
    .binduz-er-header-nav
    .navigation
    .navbar
    .binduz-er-navbar-btn {
    display: none;
  }
}
@media (max-width: 767px) {
  .binduz-er-header-area.binduz-er-header-area-2
    .binduz-er-header-nav
    .navigation
    .navbar
    .binduz-er-navbar-btn {
    display: none;
  }
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-topbar-headline-2
  p {
  margin-bottom: 0;
  color: #fff;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-topbar-headline-2
  p
  a {
  color: #9bbcd4;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .nav-item
  a {
  line-height: 70px;
  color: #75a2c2;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .nav-item
  a.active,
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .nav-item
  a:hover {
  color: #fff;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .nav-item.active
  a {
  color: #fff;
}
.binduz-er-header-area.binduz-er-header-area-2
  .binduz-er-header-nav
  .nav-item
  .sub-menu
  > li
  a {
  color: #777777;
}
.binduz-er-header-area.binduz-er-header-area-3 {
  background: #0c0c0c;
}
.binduz-er-header-area.binduz-er-header-area-3 .binduz-er-header-nav {
  padding: 0;
  background-color: transparent;
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .navbar-brand {
  margin-bottom: -20px;
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  > a {
  color: #999999;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-header-area.binduz-er-header-area-3
    .navigation
    .navbar
    .navbar-nav
    .nav-item
    > a {
    margin: 0 10px;
  }
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .navbar-nav
  .nav-item.active
  > a,
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .navbar-nav
  .nav-item:hover
  > a {
  color: #fff;
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .binduz-er-navbar-btn {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-widget
  a {
  height: 100px;
  width: 100px;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 100px;
  background: #111;
  font-size: 20px;
  color: #666;
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-toggle-btn {
  height: 100px;
  width: 100px;
  border-radius: 0;
  padding: 0;
  line-height: 100px;
  font-size: 20px;
  background: #191919;
  margin-left: 0;
  color: #666;
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-header-dropdown-select
  > .nice-select {
  background-color: transparent;
  color: #999999;
  border: 0;
  margin-right: 45px;
  width: 70px;
  padding: 0;
}
.binduz-er-header-area.binduz-er-header-area-3
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-header-dropdown-select
  > .nice-select::after {
  right: 6px;
}
.binduz-er-header-area.binduz-er-header-area-4 .binduz-er-header-nav {
  box-shadow: none;
  padding: 0;
  background: transparent;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar {
  padding: 7px 0 0;
  align-items: center;
  background-color: transparent;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar.binduz-er-page {
  padding: 40px 0;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation {
  background: #fff;
  width: 100%;
  margin-left: 30px;
  padding-left: 50px;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  > a {
  line-height: 80px;
  color: #999;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .navbar-nav
  .nav-item.active
  > a,
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .navbar-nav
  .nav-item:hover
  > a {
  color: #000;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li
  a {
  color: #999;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  > li
  a:hover {
  color: #000;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-search-btn {
  background: #e74d58;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-search-btn
  a {
  display: inline-block;
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  color: #fff;
  font-size: 20px;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .navigation
  .navbar
  .binduz-er-navbar-btn
  .binduz-er-toggle-btn {
  margin-left: 2px;
  border-radius: 0;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: #e74d58;
}
.binduz-er-header-area.binduz-er-header-area-4
  .binduz-er-header-nav
  .binduz-er-header-meddle-bar
  .binduz-er-logo {
  margin: 0;
  width: 260px;
}
@media (max-width: 767px) {
  .binduz-er-header-area.binduz-er-header-area-4
    .binduz-er-header-nav
    .binduz-er-header-meddle-bar
    .binduz-er-logo {
    min-width: 160px;
  }
}

.binduz-er-header-nav {
  padding: 22px 0;
  box-shadow: 0px -10px 38px 0 rgba(46, 63, 99, 0.15);
  background: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-header-nav {
    padding: 15px 0;
    top: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-header-nav {
    padding: 15px 0;
    top: 0;
  }
}
.binduz-er-header-nav.binduz-er-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
  z-index: 999;
  animation: sticky 1.2s;
}
.binduz-er-header-nav.binduz-er-sticky .navbar .navbar-nav .nav-item a {
  line-height: 80px;
  color: #000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-header-nav.binduz-er-sticky .navbar .navbar-nav .nav-item a {
    line-height: 40px;
    color: #fff;
  }
}
@media (max-width: 767px) {
  .binduz-er-header-nav.binduz-er-sticky .navbar .navbar-nav .nav-item a {
    line-height: 40px;
    color: #fff;
  }
}
.binduz-er-header-nav.binduz-er-sticky
  .navbar
  .navbar-nav
  .nav-item
  .sub-menu
  li
  a {
  line-height: 2.5;
  color: #000;
}
.binduz-er-header-nav.binduz-er-sticky
  .navbar
  .binduz-er-navbar-toggler
  .binduz-er-toggler-icon {
  background: #000;
}

.navbar {
  position: relative;
  padding: 0;
  flex-wrap: inherit;
}
.navbar .binduz-er-country-flag img {
  border: 5px solid #fff;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(60, 110, 203, 0.2);
}
.navbar .navbar-nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav {
    margin-right: 0;
  }
}
.navbar .navbar-nav.right-menu .nav-item a {
  text-align: center;
}
.navbar .navbar-nav.right-menu .nav-item .sub-menu > li a i {
  float: left;
}
.navbar .navbar-nav.right-menu .nav-item .sub-menu > li .sub-menu {
  left: auto;
  right: 100%;
}
.navbar .navbar-nav.right-menu .nav-item .sub-menu > li .sub-menu li .sub-menu {
  left: auto;
  right: 100%;
}
.navbar .navbar-nav .nav-item {
  position: relative;
}
.navbar .navbar-nav .nav-item:hover a,
.navbar .navbar-nav .nav-item.active a {
  color: #000;
}
.navbar .navbar-nav .nav-item a {
  font-size: 16px;
  font-weight: 700;
  color: #666666;
  text-transform: capitalize;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin: 0 32px;
  line-height: 45px;
  padding: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item a {
    margin: 0 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item a {
    color: #000;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item a {
    color: #000;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item a > i {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item a > i {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item a {
    padding: 0;
    display: block;
    border: 0;
    margin: 0;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item a {
    padding: 0;
    display: block;
    border: 0;
    margin: 0;
    line-height: 40px;
  }
}
.navbar .navbar-nav .nav-item a span {
  padding-left: 5px;
  font-size: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item a span {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item a span {
    display: none;
  }
}
.navbar .navbar-nav .nav-item a i {
  padding-left: 6px;
}
.navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.navbar .navbar-nav .nav-item .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: auto;
  min-width: 205px;
  max-width: 300px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .navbar .navbar-nav .nav-item .sub-menu {
    width: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .sub-menu {
    width: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
.navbar .navbar-nav .nav-item .sub-menu > li {
  position: relative;
}
.navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
  margin-left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
.navbar .navbar-nav .nav-item .sub-menu > li .sub-nav-toggler {
  color: #12238c;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.navbar .navbar-nav .nav-item .sub-menu > li a {
  display: block;
  padding: 0px 30px;
  position: relative;
  color: #777777;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .navbar .navbar-nav .nav-item .sub-menu > li a {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .sub-menu > li a {
    padding: 0 20px;
  }
}
.navbar .navbar-nav .nav-item .sub-menu > li a i {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item .sub-menu > li a i {
    display: none;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item .sub-menu > li a i {
    display: none;
  }
}
.navbar .navbar-nav .nav-item .sub-menu > li a .sub-nav-toggler i {
  display: inline-block;
}
.navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
.navbar .navbar-nav .nav-item .sub-menu > li .sub-menu li {
  position: relative;
}
.navbar .navbar-nav .nav-item .sub-menu > li .sub-menu li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.navbar .navbar-nav .nav-item .sub-menu > li .sub-menu li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.navbar .navbar-nav .nav-item .sub-menu > li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.navbar .navbar-nav .nav-item .sub-menu > li:hover .sub-nav-toggler {
  color: #f63a3a;
}
.navbar .navbar-nav .nav-item .sub-menu > li:hover > a {
  color: #f63a3a;
}
.navbar .navbar-nav .nav-item:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.navbar .navbar-nav .nav-item .sub-nav-toggler {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item .sub-nav-toggler {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px;
    font-size: 16px;
    background: none;
    border: 0;
    color: #000;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item .sub-nav-toggler {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px;
    font-size: 16px;
    background: none;
    border: 0;
    color: #000;
  }
}
@media (max-width: 767px) {
  .navbar .binduz-er-navbar-btn .binduz-er-widget {
    display: none !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .navbar .binduz-er-navbar-btn .binduz-er-widget {
    display: flex !important;
  }
}
.navbar .binduz-er-navbar-btn .binduz-er-widget a {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  line-height: 15px;
}
.navbar .binduz-er-navbar-btn .binduz-er-widget a:first-child {
  border-left: 0;
}
.navbar .binduz-er-navbar-btn .binduz-er-toggle-btn {
  display: inline-block;
  height: 45px;
  width: 45px;
  background: #f63a3a;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 45px;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .binduz-er-navbar-btn {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
  }
}
@media (max-width: 767px) {
  .navbar .binduz-er-navbar-btn {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
  }
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
.binduz-er-breadcrumb-area {
  margin-bottom: 40px;
  border-top: 2px solid #ededed;
  border-bottom: 2px solid #ededed;
}
.binduz-er-breadcrumb-area .binduz-er-breadcrumb-box {
  padding: 26px 0;
}
.binduz-er-breadcrumb-area .binduz-er-breadcrumb-box nav ol {
  margin: 0;
}
.binduz-er-breadcrumb-area .binduz-er-breadcrumb-box nav ol li {
  color: #000;
}
.binduz-er-breadcrumb-area .binduz-er-breadcrumb-box nav ol li a {
  color: #999;
}
.binduz-er-breadcrumb-area
  .binduz-er-breadcrumb-box
  nav
  ol
  li.breadcrumb-item
  + .breadcrumb-item {
  margin-left: 21px;
}
.binduz-er-breadcrumb-area
  .binduz-er-breadcrumb-box
  nav
  ol
  li.breadcrumb-item
  + .breadcrumb-item::before {
  color: #999;
  content: var(--bs-breadcrumb-divider, ">");
  padding-right: 21px;
}

/*===========================
    3.BINDUZ HERO css 
===========================*/
.binduz-er-hero-area {
  padding: 234px 0;
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-hero-area {
    padding: 134px 0 234px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-hero-area {
    padding: 134px 0 234px;
  }
}
@media (max-width: 767px) {
  .binduz-er-hero-area {
    padding: 100px 0 100px;
  }
}
.binduz-er-hero-area .binduz-er-bg-cover {
  background-image: url(../images/hero-bg-1.jpg);
}
.binduz-er-hero-area .binduz-er-bg-cover::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.binduz-er-hero-area .binduz-er-bg-cover.item-2 {
  background-image: url(../images/hero-bg-2.jpg);
}
.binduz-er-hero-area .binduz-er-bg-cover.item-3 {
  background-image: url(../images/hero-bg-3.jpg);
}
.binduz-er-hero-area .binduz-er-hero-news-portal {
  position: absolute;
  right: 80px;
  bottom: 0;
  padding: 20px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  width: 840px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-hero-area .binduz-er-hero-news-portal {
    right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-hero-area .binduz-er-hero-news-portal {
    display: none;
  }
}

.binduz-er-hero-news-content .binduz-er-hero-meta {
  display: flex;
  align-items: center;
}
.binduz-er-hero-news-content .binduz-er-hero-meta .binduz-er-meta-category a {
  background: #f63a3a;
  color: #fff;
  line-height: 30px;
  width: 100px;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
}
.binduz-er-hero-news-content .binduz-er-hero-meta .binduz-er-meta-date {
  margin-left: 20px;
}
.binduz-er-hero-news-content .binduz-er-hero-meta .binduz-er-meta-date span {
  color: #c8c8c8;
  font-size: 14px;
}
.binduz-er-hero-news-content .binduz-er-hero-title {
  padding-top: 20px;
  padding-bottom: 12px;
}
.binduz-er-hero-news-content .binduz-er-hero-title .binduz-er-title {
  color: #fff;
  font-size: 48px;
  line-height: 58px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-hero-news-content .binduz-er-hero-title .binduz-er-title {
    font-size: 42px;
    line-height: 52px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-hero-news-content .binduz-er-hero-title .binduz-er-title {
    font-size: 34px;
    line-height: 44px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-hero-news-content .binduz-er-hero-title .binduz-er-title {
    font-size: 36px;
    line-height: 36px;
  }
}
@media (max-width: 767px) {
  .binduz-er-hero-news-content .binduz-er-hero-title .binduz-er-title {
    font-size: 26px;
    line-height: 36px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-hero-news-content .binduz-er-hero-title .binduz-er-title {
    font-size: 30px;
    line-height: 40px;
  }
}
.binduz-er-hero-news-content .binduz-er-hero-title .binduz-er-title a {
  color: #fff;
}
.binduz-er-hero-news-content .binduz-er-meta-author {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .binduz-er-hero-news-content .binduz-er-meta-author {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-hero-news-content .binduz-er-meta-author {
    display: flex;
  }
}
.binduz-er-hero-news-content .binduz-er-meta-author .binduz-er-author img {
  margin-right: 15px;
}
.binduz-er-hero-news-content .binduz-er-meta-author .binduz-er-author span {
  color: #c8c8c8;
}
.binduz-er-hero-news-content
  .binduz-er-meta-author
  .binduz-er-author
  span
  span {
  color: #fff;
  font-weight: 700;
}
@media (max-width: 767px) {
  .binduz-er-hero-news-content .binduz-er-meta-author .binduz-er-meta-list {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-hero-news-content .binduz-er-meta-author .binduz-er-meta-list {
    margin-top: 0px;
  }
}
.binduz-er-hero-news-content .binduz-er-meta-author .binduz-er-meta-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-hero-news-content .binduz-er-meta-author .binduz-er-meta-list ul li {
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  line-height: 14px;
  border-left: 1px solid #727272;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-hero-news-content
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    margin-left: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-hero-news-content
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    border-left: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-hero-news-content
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    border-left: 1px solid #727272;
    margin-left: 15px;
  }
}

.binduz-er-hero-weather {
  flex-direction: column;
  float: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-hero-weather {
    float: left;
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .binduz-er-hero-weather {
    float: left;
    margin-top: 40px;
  }
}
.binduz-er-hero-weather .binduz-er-weather-item {
  position: relative;
  padding-left: 50px;
  margin-bottom: 27px;
}
.binduz-er-hero-weather .binduz-er-weather-item img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.binduz-er-hero-weather .binduz-er-weather-item .binduz-er-title {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
.binduz-er-hero-weather .binduz-er-weather-item span {
  color: #a7a7a7;
}

.hero-portal-area {
  width: 850px;
  height: 160px;
  margin-top: -160px;
  background: #fff;
  position: relative;
  left: auto;
  right: 0;
  z-index: 9;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  float: right;
  margin-right: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-portal-area {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    height: auto;
  }
}
@media (max-width: 767px) {
  .hero-portal-area {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    height: 300px;
  }
}

.binduz-er-hero-news-portal {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .binduz-er-hero-news-portal .binduz-er-news-portal-item {
    display: block;
  }
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item .binduz-er-thumb {
  min-width: 120px;
  margin-right: 20px;
  border-radius: 8px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .binduz-er-hero-news-portal .binduz-er-news-portal-item .binduz-er-thumb {
    margin-bottom: 20px;
  }
}
.binduz-er-hero-news-portal
  .binduz-er-news-portal-item
  .binduz-er-content
  .binduz-er-post-meta-date
  span {
  color: #666;
  font-size: 14px;
}
.binduz-er-hero-news-portal
  .binduz-er-news-portal-item
  .binduz-er-content
  .binduz-er-post-meta-date
  span
  i {
  padding-right: 6px;
}
.binduz-er-hero-news-portal
  .binduz-er-news-portal-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-hero-news-portal
    .binduz-er-news-portal-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 18px;
  }
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item:last-child {
  margin-left: 45px;
  padding-left: 45px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-hero-news-portal .binduz-er-news-portal-item:last-child {
    margin-left: 20px;
    padding-left: 20px;
  }
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item:last-child::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #eeeeee;
  height: 80px;
  width: 2px;
}

/*===========================
    4.BINDUZ TRENDING css 
===========================*/
.binduz-er-trending-area {
  padding-top: 53px;
  padding-bottom: 60px;
  z-index: 9;
  position: relative;
}
.binduz-er-trending-area .row {
  width: 100%;
}

.binduz-er-trending-box {
  margin-right: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-box {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-box {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-box {
    margin-right: 0;
    overflow: hidden;
  }
}
.binduz-er-trending-box .binduz-er-title .binduz-er-title {
  font-size: 30px;
  display: inline-block;
  position: relative;
  margin: 0;
}
.binduz-er-trending-box .binduz-er-title .binduz-er-title::before {
  position: absolute;
  content: "";
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 390px;
  background: #f63a3a;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-box .binduz-er-title .binduz-er-title::before {
    width: 115%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-box .binduz-er-title .binduz-er-title::before {
    width: 70%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-box .binduz-er-title .binduz-er-title::before {
    width: 90%;
  }
}

.binduz-er-trending-news-item {
  position: relative;
  z-index: 10;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .binduz-er-trending-news-item {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-trending-news-item {
    display: block;
  }
}
.binduz-er-trending-news-item img {
  width: 100%;
  transition: all linear 0.5s;
}
.binduz-er-trending-news-item .binduz-er-trending-news-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-content: flex-end;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta {
  position: absolute;
  left: 40px;
  bottom: 24px;
  padding-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta {
    left: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta {
    left: 20px;
    bottom: 6px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta {
    left: 20px;
    bottom: 6px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta {
    left: 20px;
    bottom: 6px;
  }
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-meta-categories {
  display: inline-block;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-meta-categories
  a {
  display: inline-block;
  background: #f63a3a;
  color: #fff;
  line-height: 30px;
  padding: 0 14px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-meta-date {
  display: inline-block;
  margin-left: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-meta-date {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-meta-date {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-meta-date {
    display: inline-block;
    margin-left: 20px;
    margin-top: 0px;
  }
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-meta-date
  span {
  color: #c8c8c8;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trending-news-title {
  margin-top: 12px;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trending-news-title
  .binduz-er-title {
  font-size: 24px;
  line-height: 34px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trending-news-title
    .binduz-er-title {
    font-size: 21px;
    line-height: 31px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trending-news-title
    .binduz-er-title {
    font-size: 17px;
    line-height: 27px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trending-news-title
    .binduz-er-title {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trending-news-title
    .binduz-er-title {
    font-size: 20px;
    line-height: 30px;
  }
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trending-news-title
  .binduz-er-title
  a {
  color: #fff;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trending-news-title
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-news-share {
  position: absolute;
  top: 30px;
  right: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-news-share {
    top: 10px;
    right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-news-share {
    display: none;
  }
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-news-share
  a {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  display: inline-block;
  border-radius: 5px;
}
.binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-news-share
  a:hover {
  background: #f63a3a;
}
.binduz-er-trending-news-item:hover > img {
  transform: scale(1.05);
}

.binduz-er-trending-news-topbar .binduz-er-news-tab-btn ul li a {
  font-size: 14px;
  color: #666;
  font-weight: 700;
  padding: 0 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-topbar .binduz-er-news-tab-btn ul li a {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-topbar .binduz-er-news-tab-btn ul li a {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-topbar .binduz-er-news-tab-btn ul li a {
    padding: 0 8px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-topbar .binduz-er-news-tab-btn ul li a {
    padding: 30px 15px 0 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-trending-news-topbar .binduz-er-news-tab-btn ul li a {
    padding: 30px 20px 0 0;
  }
}
.binduz-er-trending-news-topbar .binduz-er-news-tab-btn ul li a.active {
  background-color: transparent;
  color: #000;
}

.binduz-er-trending-news-list-item {
  margin-left: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-list-item {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-list-item {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-list-item {
    margin-left: 0;
  }
}
.binduz-er-trending-news-list-item .tab-content {
  padding-top: 50px;
}

.binduz-er-trending-news-list-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-list-box {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-list-box {
    display: block;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-list-box {
    display: block;
  }
}
.binduz-er-trending-news-list-box .binduz-er-thumb {
  min-width: 116px;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
  height: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-list-box .binduz-er-thumb {
    height: auto;
    width: 100px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-list-box .binduz-er-thumb {
    width: 100px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-list-box .binduz-er-thumb {
    width: 100px;
    margin-bottom: 15px;
  }
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories {
  display: inline-block;
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  display: inline-block;
  background: #ffeaea;
  border-radius: 5px;
  color: #f63a3a;
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 0 15px;
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date {
  display: inline-block;
  margin-left: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 5px;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 20px;
    margin-top: 0px;
  }
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  font-size: 14px;
  color: #666;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    font-size: 12px;
  }
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-trending-news-list-title {
  padding-top: 8px;
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-trending-news-list-title
  .binduz-er-title {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-trending-news-list-title
    .binduz-er-title {
    font-size: 17px;
    line-height: 27px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-trending-news-list-title
    .binduz-er-title {
    font-size: 18px;
  }
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-trending-news-list-title
  .binduz-er-title
  a {
  color: #000;
}
.binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-trending-news-list-title
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-trending-news-list-box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.binduz-er-trending-news-list-box:hover
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-sidebar-categories {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .binduz-er-sidebar-categories {
    margin-top: 50px;
  }
}
.binduz-er-sidebar-categories .binduz-er-sidebar-title {
  border-bottom: 2px solid #ffeaea;
  position: relative;
}
.binduz-er-sidebar-categories .binduz-er-sidebar-title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid #ffeaea;
  border-right: 10px solid transparent;
}
.binduz-er-sidebar-categories .binduz-er-sidebar-title .binduz-er-title {
  display: inline-block;
  background: #ffeaea;
  margin-bottom: 0;
  line-height: 30px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  color: #f63a3a;
}
.binduz-er-sidebar-categories .binduz-er-categories-list {
  margin-top: 50px;
}
.binduz-er-sidebar-categories .binduz-er-categories-list .binduz-er-item {
  background-image: url(../images/categories-list-bg-1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 20px;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-categories .binduz-er-categories-list .binduz-er-item {
    padding: 20px 20px;
  }
}
.binduz-er-sidebar-categories
  .binduz-er-categories-list
  .binduz-er-item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.binduz-er-sidebar-categories .binduz-er-categories-list .binduz-er-item a {
  display: block;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.binduz-er-sidebar-categories
  .binduz-er-categories-list
  .binduz-er-item
  a
  span {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 0 20px;
  line-height: 30px;
  border-radius: 5px;
}
.binduz-er-sidebar-categories
  .binduz-er-categories-list
  .binduz-er-item
  a
  span.binduz-er-number {
  padding: 0;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
}
.binduz-er-sidebar-categories
  .binduz-er-categories-list
  .binduz-er-item:nth-child(2) {
  background-image: url(../images/categories-list-bg-2.jpg);
}
.binduz-er-sidebar-categories
  .binduz-er-categories-list
  .binduz-er-item:nth-child(3) {
  background-image: url(../images/categories-list-bg-3.jpg);
}
.binduz-er-sidebar-categories
  .binduz-er-categories-list
  .binduz-er-item:nth-child(4) {
  background-image: url(../images/categories-list-bg-4.jpg);
}
.binduz-er-sidebar-categories
  .binduz-er-categories-list
  .binduz-er-item:nth-child(5) {
  background-image: url(../images/categories-list-bg-5.jpg);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-featured-slider {
    margin-top: 0;
  }
}

.binduz-er-trending-news-box-area {
  padding-top: 10px;
}
.binduz-er-trending-news-box-area .binduz-er-trending-news-box {
  padding: 35px 40px 0;
  background: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-box-area .binduz-er-trending-news-box {
    padding: 10px 10px 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-box-area .binduz-er-trending-news-box {
    padding: 15px 20px 0;
  }
}

.binduz-er-trending-news-box .binduz-er-trending-news-topbar {
  margin-bottom: 13px;
}
.binduz-er-trending-news-box .binduz-er-trending-news-topbar .binduz-er-title {
  font-size: 24px;
  width: 70%;
  position: relative;
  z-index: 5;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title {
    width: 64%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title {
    width: 57%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title {
    width: 55%;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title {
    width: 100%;
  }
}
.binduz-er-trending-news-box
  .binduz-er-trending-news-topbar
  .binduz-er-title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 78%;
  background: #f3f3f3;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title::before {
    width: 72%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title::before {
    width: 62%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title::before {
    width: 44%;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title::before {
    width: 0%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-trending-news-box
    .binduz-er-trending-news-topbar
    .binduz-er-title::before {
    width: 57%;
  }
}
.binduz-er-trending-news-box .binduz-er-trending-news-topbar ul li {
  margin-left: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-news-box .binduz-er-trending-news-topbar ul li {
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-news-box .binduz-er-trending-news-topbar ul li {
    margin-left: 0px;
    margin-right: 15px;
  }
}
.binduz-er-trending-news-box .binduz-er-trending-news-topbar ul li a {
  padding: 0;
  color: #666;
  font-size: 14px;
  font-weight: 700;
}
.binduz-er-trending-news-box .binduz-er-trending-news-topbar ul li a.active {
  background-color: transparent;
  color: #000;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-box
    .binduz-er-video-post.binduz-er-recently-viewed-item
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item {
    display: block;
  }
}
.binduz-er-trending-news-box
  .binduz-er-video-post.binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #e74d58;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-news-box
    .binduz-er-video-post.binduz-er-recently-viewed-item
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 0;
    margin-top: 10px;
  }
}
.binduz-er-trending-news-box
  .binduz-er-video-post.binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-weight: 700;
}
.binduz-er-trending-news-box
  .binduz-er-video-post.binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}

/*===========================
    5.BINDUZ FEATURED css 
===========================*/
.binduz-er-featured-area {
  background: #f7f7f7;
  padding: 60px 0;
}
.binduz-er-featured-area .binduz-er-featured-title .binduz-er-title {
  font-size: 30px;
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
}
.binduz-er-featured-area .binduz-er-featured-title .binduz-er-title::before {
  position: absolute;
  content: "";
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 200px;
  background: #f63a3a;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-featured-area .binduz-er-featured-title .binduz-er-title::before {
    width: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-featured-area .binduz-er-featured-title .binduz-er-title::before {
    width: 60%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-featured-area .binduz-er-featured-title .binduz-er-title::before {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .binduz-er-featured-area .binduz-er-featured-title .binduz-er-title::before {
    width: 50%;
  }
}

.binduz-er-featured-slider-item .binduz-er-trending-news-list-box {
  border-bottom: 0;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: block;
}
.binduz-er-featured-slider-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb {
  min-width: 100%;
  border-radius: 0;
  height: auto;
}
.binduz-er-featured-slider-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb
  img {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  width: 100%;
}
.binduz-er-featured-slider-item
  .binduz-er-trending-news-list-box
  .binduz-er-content {
  padding: 30px 30px 22px;
}
.binduz-er-featured-slider-item
  .binduz-er-trending-news-list-box:hover
  .binduz-er-thumb
  img {
  transform: scale(1.1);
}
.binduz-er-featured-slider-item .slick-arrow {
  position: absolute;
  right: 0;
  top: -65px;
  cursor: pointer;
  z-index: 9;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #000;
  background: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-featured-slider-item .slick-arrow:hover {
  background: #f63a3a;
  color: #fff;
}
.binduz-er-featured-slider-item .slick-arrow.prev {
  right: 40px;
}
.binduz-er-featured-slider-item .slick-arrow.next {
  right: 0px;
}

.binduz-er-sidebar-social .binduz-er-sidebar-title {
  border-bottom: 2px solid #fff;
  position: relative;
}
.binduz-er-sidebar-social .binduz-er-sidebar-title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid #fff;
  border-right: 10px solid transparent;
}
.binduz-er-sidebar-social .binduz-er-sidebar-title .binduz-er-title {
  display: inline-block;
  background: #fff;
  margin-bottom: 0;
  line-height: 30px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  color: #f63a3a;
}
.binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list {
  margin-top: 35px;
}
.binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list a {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 10px;
  padding: 10px 30px 10px 10px;
  align-items: center;
  margin-bottom: 8px;
}
.binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list a span {
  font-size: 14px;
  color: #000;
}
.binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list a span span {
  font-weight: 700;
}
.binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list a span i {
  height: 40px;
  width: 40px;
  background: #3b5999;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 6px;
  margin-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list a span i {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list a span i {
    margin-right: 5px;
  }
}
.binduz-er-sidebar-social
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(2)
  span
  i {
  background: #55acee;
}
.binduz-er-sidebar-social
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(3)
  span
  i {
  background: #0057ff;
}
.binduz-er-sidebar-social
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(4)
  span
  i {
  background: #cd201f;
}
.binduz-er-sidebar-social .binduz-er-social-list .binduz-er-list a:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
.binduz-er-sidebar-social .binduz-er-sidebar-add {
  background-image: url(../images/space-bg-1.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  padding: 14px 20px 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-sidebar-social .binduz-er-sidebar-add {
    width: 40%;
  }
}
.binduz-er-sidebar-social .binduz-er-sidebar-add .binduz-er-title {
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  text-transform: inherit;
}
.binduz-er-sidebar-social .binduz-er-sidebar-add .binduz-er-title span {
  font-weight: 300;
  display: block;
}
.binduz-er-sidebar-social .binduz-er-sidebar-add a {
  background: #f63a3a;
  color: #fff;
  border-color: #f63a3a;
  padding: 0 30px;
  line-height: 40px;
}

.binduz-er-featured-2-area .binduz-er-featured-title {
  border-top: 1px solid #eaeaea;
  padding-top: 26px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-featured-2-area .binduz-er-featured-title {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .binduz-er-featured-2-area .binduz-er-featured-title {
    margin-top: 40px;
  }
}
.binduz-er-featured-2-area .binduz-er-featured-title .binduz-er-title {
  font-size: 18px;
  font-weight: 500;
  color: #f63a3a;
  margin-bottom: 38px;
}

.binduz-er-featured-slider-2 .slick-arrow {
  position: absolute;
  top: -60px;
  z-index: 9;
  cursor: pointer;
  font-size: 18px;
}
.binduz-er-featured-slider-2 .slick-arrow.prev {
  right: 20px;
}
.binduz-er-featured-slider-2 .slick-arrow.next {
  right: 0;
}

.binduz-er-featured-item .binduz-er-thumb {
  position: relative;
}
.binduz-er-featured-item .binduz-er-thumb .binduz-er-icon {
  position: absolute;
  left: 0;
  bottom: 0;
}
.binduz-er-featured-item .binduz-er-thumb .binduz-er-icon a {
  height: 50px;
  width: 50px;
  background: #f63a3a;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  color: #fff;
}
.binduz-er-featured-item .binduz-er-thumb img {
  width: 100%;
}
.binduz-er-featured-item .binduz-er-content {
  padding-top: 22px;
}
.binduz-er-featured-item .binduz-er-content .binduz-er-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}
.binduz-er-featured-item .binduz-er-content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-featured-item .binduz-er-content ul li {
  color: #777;
  border-top: 1px solid #eaeaea;
  padding: 15px 0;
}
.binduz-er-featured-item .binduz-er-content ul li i {
  color: #d2d2d2;
  padding-right: 8px;
}

/*===========================
    6.BINDUZ VIDEO POST css 
===========================*/
.binduz-er-video-post-area {
  padding-top: 53px;
  padding-bottom: 20px;
}
.binduz-er-video-post-area .binduz-er-video-post-tab ul li a {
  padding: 0;
  margin-left: 35px;
  font-size: 14px;
  font-weight: 700;
  color: #666;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post-area .binduz-er-video-post-tab ul li a {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-video-post-area .binduz-er-video-post-tab ul li a {
    margin-left: 0px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post-area .binduz-er-video-post-tab ul li a {
    margin-left: 0px;
    margin-right: 16px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-video-post-area .binduz-er-video-post-tab ul li a {
    margin-left: 0px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.binduz-er-video-post-area .binduz-er-video-post-tab ul li a.active {
  background-color: transparent;
  color: #000;
}

.binduz-er-video-post-topbar .binduz-er-video-post-title .binduz-er-title {
  font-size: 30px;
  display: block;
  position: relative;
  margin-bottom: 30px;
}
.binduz-er-video-post-topbar
  .binduz-er-video-post-title
  .binduz-er-title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 83%;
  background: #f63a3a;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-video-post-topbar
    .binduz-er-video-post-title
    .binduz-er-title::before {
    width: 76%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post-topbar
    .binduz-er-video-post-title
    .binduz-er-title::before {
    width: 71%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-video-post-topbar
    .binduz-er-video-post-title
    .binduz-er-title::before {
    width: 71%;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post-topbar
    .binduz-er-video-post-title
    .binduz-er-title::before {
    display: none;
  }
}

.binduz-er-video-post-item .binduz-er-trending-news-list-box {
  margin-bottom: 30px;
  padding-bottom: 0;
  border-bottom: 0;
  display: block;
}
.binduz-er-video-post-item .binduz-er-trending-news-list-box .binduz-er-thumb {
  min-width: 100%;
  height: auto;
  margin-right: 0;
  margin-bottom: 20px;
  position: relative;
}
.binduz-er-video-post-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb
  .binduz-er-play {
  position: absolute;
  right: 20px;
  top: 20px;
}
.binduz-er-video-post-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb
  .binduz-er-play
  a {
  height: 50px;
  width: 50px;
  display: inline-block;
  line-height: 50px;
  color: #f63a3a;
  border-radius: 5px;
  background: #fff;
  text-align: center;
}
.binduz-er-video-post-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb
  img {
  width: 100%;
  transition: all linear 0.5s;
}
.binduz-er-video-post-item
  .binduz-er-trending-news-list-box.binduz-er-main-item
  .binduz-er-content
  .binduz-er-trending-news-list-title
  .binduz-er-title {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-video-post-item
    .binduz-er-trending-news-list-box.binduz-er-main-item
    .binduz-er-content
    .binduz-er-trending-news-list-title
    .binduz-er-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post-item
    .binduz-er-trending-news-list-box.binduz-er-main-item
    .binduz-er-content
    .binduz-er-trending-news-list-title
    .binduz-er-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-video-post-item
    .binduz-er-trending-news-list-box.binduz-er-main-item
    .binduz-er-content
    .binduz-er-trending-news-list-title
    .binduz-er-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.binduz-er-video-post-item
  .binduz-er-trending-news-list-box:hover
  .binduz-er-thumb
  img {
  transform: scale(1.1);
}

.binduz-er-news-slider-2-item .binduz-er-news-viewed-most {
  margin: 0 2px;
}
.binduz-er-news-slider-2-item .binduz-er-news-viewed-most .binduz-er-thumb {
  overflow: hidden;
}
.binduz-er-news-slider-2-item .binduz-er-news-viewed-most .binduz-er-thumb img {
  width: 100%;
}
.binduz-er-news-slider-2-item .binduz-er-news-viewed-most .binduz-er-content {
  padding: 30px 60px 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-news-slider-2-item .binduz-er-news-viewed-most .binduz-er-content {
    padding: 30px 20px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-slider-2-item .binduz-er-news-viewed-most .binduz-er-content {
    padding: 30px 20px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-news-slider-2-item .binduz-er-news-viewed-most .binduz-er-content {
    padding: 30px 20px 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-slider-2-item .binduz-er-news-viewed-most .binduz-er-content {
    padding: 30px 30px 0;
  }
}
.binduz-er-news-slider-2-item
  .binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}
.binduz-er-news-slider-2-item
  .binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-news-slider-2-item
    .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-title {
    font-size: 26px;
    line-height: 36px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-slider-2-item
    .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-title {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-news-slider-2-item
    .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-slider-2-item
    .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-title {
    font-size: 17px;
    line-height: 27px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-slider-2-item
    .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-title {
    font-size: 26px;
    line-height: 36px;
  }
}
.binduz-er-news-slider-2-item
  .binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #ffeaea;
  color: #f63a3a;
}
.binduz-er-news-slider-2-item
  .binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  margin-left: 14px;
}
.binduz-er-news-slider-2-item
  .binduz-er-news-viewed-most:hover
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}

.binduz-er-video-post-4-area {
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-video-post-4-area {
    padding-bottom: 60px;
  }
}
.binduz-er-video-post-4-area .binduz-er-top-news-2-item {
  margin-right: 0;
}
.binduz-er-video-post-4-area.home-11-video-post {
  padding-bottom: 30px;
}
.binduz-er-video-post-4-area.home-11-video-post .binduz-er-recent-news-box {
  margin-top: 10px;
}
.binduz-er-video-post-4-area.home-12-video-post {
  padding-top: 40px;
  padding-bottom: 30px;
}
.binduz-er-video-post-4-area.home-12-video-post .binduz-er-recent-news-box {
  margin-top: 0;
}

.binduz-er-video-post-box {
  padding: 35px 40px 5px;
  position: relative;
  z-index: 10;
  background: #fff;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-video-post-box {
    padding: 15px 20px 5px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post-box {
    padding: 15px 20px 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post-box {
    padding: 10px;
  }
}
.binduz-er-video-post-box .binduz-er-video-post-title .binduz-er-title {
  font-size: 24px;
  margin-bottom: 22px;
}
.binduz-er-video-post-box .binduz-er-video-post .binduz-er-latest-news-item {
  padding: 0;
}
.binduz-er-video-post-box
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-thumb
  .binduz-er-play
  a {
  background: #fff;
  color: #e74d58;
}
.binduz-er-video-post-box
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date {
  margin: 0;
}
.binduz-er-video-post-box
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 20px;
  line-height: 30px;
}
.binduz-er-video-post-box
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-video-post-box .binduz-er-trending-video-thumbs {
  position: absolute;
  left: 50%;
  bottom: 44px;
  transform: translateX(-50%);
  z-index: 10;
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post-box .binduz-er-trending-video-thumbs {
    bottom: 50px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post-box .binduz-er-trending-video-thumbs {
    bottom: 50px;
  }
}
.binduz-er-video-post-box .binduz-er-trending-video-thumbs ul {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
}
.binduz-er-video-post-box .binduz-er-trending-video-thumbs ul li {
  display: inline-block;
  width: 130px;
  margin: 0 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post-box .binduz-er-trending-video-thumbs ul li {
    width: 100px;
    margin: 0 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post-box .binduz-er-trending-video-thumbs ul li {
    width: 100px;
    margin: 0 10px;
  }
}
.binduz-er-video-post-box .binduz-er-trending-video-thumbs ul li button {
  padding: 0;
  border-radius: 0;
  border: 0;
}
.binduz-er-video-post-box .binduz-er-trending-video-thumbs ul li button.active {
  margin-top: -20px;
}

.binduz-er-trending-video-play-news .binduz-er-top-news-2-item {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 10px;
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item {
  height: 610px;
  background-image: url(../images/trending-video-thumb-1.jpg);
  position: relative;
  display: block;
}
@media (max-width: 767px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item {
    height: 450px;
  }
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item.item-2 {
  background-image: url(../images/trending-video-thumb-2.jpg);
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item.item-3 {
  background-image: url(../images/trending-video-thumb-3.jpg);
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta {
  left: 60px;
  bottom: 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta {
    left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta {
    left: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta {
    left: 20px;
  }
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-meta-date
  span {
  color: rgba(255, 255, 255, 0.7);
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trending-news-title
  .binduz-er-title {
  font-size: 24px;
  margin-bottom: 57px;
}
@media (max-width: 767px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trending-news-title
    .binduz-er-title {
    font-size: 16px;
    line-height: 26px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trending-news-title
    .binduz-er-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trenving-video-thumbs {
  margin-right: 60px;
}
@media (max-width: 767px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trenving-video-thumbs {
    margin-right: 20px;
  }
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trenving-video-thumbs
  ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trenving-video-thumbs
  ul
  li {
  display: inline-block;
  max-width: 130px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-news-meta
    .binduz-er-trenving-video-thumbs
    ul
    li {
    max-width: 110px;
  }
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trenving-video-thumbs
  ul
  li
  img {
  width: auto;
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-trenving-video-thumbs
  ul
  li:nth-child(2) {
  margin-top: -20px;
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-play {
  position: absolute;
  top: 60px;
  left: 60px;
}
@media (max-width: 767px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-play {
    left: 20px;
    top: 20px;
  }
}
.binduz-er-trending-video-play-news
  .binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-play
  a {
  display: inline-block;
  height: 100px;
  width: 100px;
  background: #fff;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  color: #000;
  font-size: 20px;
  color: #e74d58;
}
@media (max-width: 767px) {
  .binduz-er-trending-video-play-news
    .binduz-er-top-news-2-item
    .binduz-er-trending-news-item
    .binduz-er-trending-news-overlay
    .binduz-er-trending-play
    a {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}

.binduz-er-trending-video-news-title .binduz-er-title {
  font-size: 24px;
  position: relative;
  z-index: 5;
  margin-bottom: 29px;
}
.binduz-er-trending-video-news-title .binduz-er-title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 66%;
  background: #f4f4f4;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-trending-video-news-title .binduz-er-title::before {
    width: 55%;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-video-news-title .binduz-er-title::before {
    width: 0%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-trending-video-news-title .binduz-er-title::before {
    width: 55%;
  }
}

.binduz-er-social-news-box {
  background: #fff;
  padding: 40px;
  margin-top: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-social-news-box {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-social-news-box {
    padding: 20px;
  }
}
.binduz-er-social-news-box .binduz-er-title {
  font-size: 24px;
  position: relative;
  z-index: 5;
  margin-bottom: 29px;
  margin-right: 105px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-social-news-box .binduz-er-title {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-social-news-box .binduz-er-title {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-social-news-box .binduz-er-title {
    margin-right: 0;
  }
}
.binduz-er-social-news-box .binduz-er-title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 80%;
  background: #f4f4f4;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-social-news-box .binduz-er-title::before {
    width: 75%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-social-news-box .binduz-er-title::before {
    width: 75%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-social-news-box .binduz-er-title::before {
    width: 72%;
  }
}
@media (max-width: 767px) {
  .binduz-er-social-news-box .binduz-er-title::before {
    width: 0%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-social-news-box .binduz-er-title::before {
    width: 62%;
  }
}
.binduz-er-social-news-box .binduz-er-social-news-slide .col-lg-4 {
  margin-left: 15px;
  margin-right: 15px;
}
.binduz-er-social-news-box .binduz-er-social-news-slide .slick-arrow {
  position: absolute;
  top: -60px;
  height: 30px;
  width: 30px;
  background: #f8f8f8;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  z-index: 9;
  padding: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-social-news-box .binduz-er-social-news-slide .slick-arrow:hover {
  background: #e74d58;
  color: #fff;
}
.binduz-er-social-news-box .binduz-er-social-news-slide .slick-arrow.prev {
  right: 52px;
}
.binduz-er-social-news-box .binduz-er-social-news-slide .slick-arrow.next {
  right: 15px;
}

.binduz-er-social-news-box .binduz-er-social-news-item {
  position: relative;
  overflow: hidden;
}
.binduz-er-social-news-box .binduz-er-social-news-item img {
  width: 100%;
  transition: all linear 0.5s;
}
.binduz-er-social-news-box .binduz-er-social-news-item .binduz-er-social-btn {
  position: absolute;
  left: 0;
  top: 0;
}
.binduz-er-social-news-box .binduz-er-social-news-item .binduz-er-social-btn a {
  height: 60px;
  width: 60px;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  background: #3b5999;
  text-align: center;
  line-height: 60px;
}
.binduz-er-social-news-box
  .binduz-er-social-news-item
  .binduz-er-social-btn.binduz-er-twitter
  a {
  background: #55acee;
}
.binduz-er-social-news-box
  .binduz-er-social-news-item
  .binduz-er-social-btn.binduz-er-youtube
  a {
  background: #cd201f;
}
.binduz-er-social-news-box .binduz-er-social-news-item:hover img {
  transform: scale(1.1);
}

.binduz-er-recent-news-box {
  padding: 35px 40px 27px;
  margin-top: 40px;
  background: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-recent-news-box {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-recent-news-box {
    padding: 20px;
  }
}
.binduz-er-recent-news-box .binduz-er-recent-news-title .binduz-er-title {
  font-size: 24px;
  position: relative;
  z-index: 5;
  margin-bottom: 29px;
}
.binduz-er-recent-news-box
  .binduz-er-recent-news-title
  .binduz-er-title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 80%;
  background: #f4f4f4;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-recent-news-box
    .binduz-er-recent-news-title
    .binduz-er-title::before {
    width: 74%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-recent-news-box
    .binduz-er-recent-news-title
    .binduz-er-title::before {
    width: 71%;
  }
}
@media (max-width: 767px) {
  .binduz-er-recent-news-box
    .binduz-er-recent-news-title
    .binduz-er-title::before {
    width: 0%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-recent-news-box
    .binduz-er-recent-news-title
    .binduz-er-title::before {
    width: 62%;
  }
}
.binduz-er-recent-news-box nav {
  padding-top: 10px;
}
.binduz-er-recent-news-box nav .pagination .page-item a {
  display: inline-block;
  padding: 0;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #f8f8f8;
  border: 0;
  border-radius: 0;
  margin-right: 10px;
  color: #777777;
}
@media (max-width: 767px) {
  .binduz-er-recent-news-box nav .pagination .page-item a {
    height: 34px;
    width: 34px;
    line-height: 34px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-recent-news-box nav .pagination .page-item a {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 16px;
  }
}
.binduz-er-recent-news-box nav .pagination .page-item:hover a,
.binduz-er-recent-news-box nav .pagination .page-item.active a {
  background: #e74d58;
  color: #fff;
}

.binduz-er-recent-news-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-recent-news-item {
    display: block;
  }
}
@media (max-width: 767px) {
  .binduz-er-recent-news-item {
    display: block;
  }
}
.binduz-er-recent-news-item .binduz-er-thumb {
  min-width: 300px;
  margin-right: 40px;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-recent-news-item .binduz-er-thumb {
    min-width: 290px;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-recent-news-item .binduz-er-thumb {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-recent-news-item .binduz-er-thumb {
    margin-right: 0;
    margin-bottom: 20px;
    min-width: 100%;
  }
}
.binduz-er-recent-news-item .binduz-er-thumb img {
  width: 100%;
  transition: all linear 0.5s;
}
.binduz-er-recent-news-item .binduz-er-content .binduz-er-meta-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .binduz-er-recent-news-item .binduz-er-content .binduz-er-meta-item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-recent-news-item .binduz-er-content .binduz-er-meta-item {
    display: flex;
  }
}
.binduz-er-recent-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #e74d58;
  display: inline-block;
  line-height: 30px;
  padding: 0 15px;
  color: #fff;
  font-weight: 700;
}
@media (max-width: 767px) {
  .binduz-er-recent-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-categories
    a {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-recent-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-categories
    a {
    margin-bottom: 0px;
  }
}
.binduz-er-recent-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  color: #777777;
  margin-left: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-recent-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    font-size: 12px;
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  .binduz-er-recent-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    padding-top: 10px;
    margin-left: 0px;
  }
}
.binduz-er-recent-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span
  i {
  padding-right: 6px;
}
.binduz-er-recent-news-item .binduz-er-content .binduz-er-title {
  font-size: 20px;
  margin-top: 14px;
  margin-bottom: 20px;
}
.binduz-er-recent-news-item .binduz-er-content .binduz-er-title a {
  color: #000;
}
.binduz-er-recent-news-item .binduz-er-content .binduz-er-title a:hover {
  color: #e74d58;
}
.binduz-er-recent-news-item .binduz-er-content p {
  margin-bottom: 0;
  padding-right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-recent-news-item .binduz-er-content p {
    padding-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-recent-news-item .binduz-er-content p {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-recent-news-item .binduz-er-content p {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-recent-news-item .binduz-er-content p {
    padding-right: 0;
  }
}
.binduz-er-recent-news-item:hover .binduz-er-thumb img {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-video-post-sidebar {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post-sidebar {
    margin-top: 40px;
  }
}

.binduz-er-social-box {
  background: #fff;
  padding: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-social-box {
    padding: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-social-box {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-social-box {
    padding: 20px;
  }
}
.binduz-er-social-box .binduz-er-social-title .title {
  font-size: 18px;
  margin-bottom: 24px;
}
.binduz-er-social-box .binduz-er-social-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-social-box .binduz-er-social-list ul li a {
  display: block;
  background: #f7f7f7;
  padding: 10px;
  margin-bottom: 8px;
  color: #999;
  font-size: 14px;
}
.binduz-er-social-box .binduz-er-social-list ul li a i {
  height: 40px;
  width: 40px;
  background: #3b5999;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-right: 30px;
  font-size: 18px;
}
.binduz-er-social-box .binduz-er-social-list ul li a span {
  font-weight: 700;
  color: #000;
}
.binduz-er-social-box .binduz-er-social-list ul li:last-child a {
  margin-bottom: 0;
}
.binduz-er-social-box .binduz-er-social-list ul li:nth-child(2) a i {
  background: #55acee;
}
.binduz-er-social-box .binduz-er-social-list ul li:nth-child(3) a i {
  background: #0057ff;
}
.binduz-er-social-box .binduz-er-social-list ul li:nth-child(4) a i {
  background: #cd201f;
}

.binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box {
  padding: 30px;
  background: #fff;
  margin-top: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box {
    padding: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box {
    padding: 20px;
  }
}
.binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box
  .binduz-er-popular-news-title {
  border-top: 0;
  padding-top: 0;
}
.binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box
  .binduz-er-popular-news-title
  ul
  li.nav-item
  a {
  font-size: 18px;
  font-weight: 500;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box
    .binduz-er-popular-news-title
    ul
    li.nav-item
    a {
    font-size: 15px;
  }
}
.binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box
  .binduz-er-popular-news-title
  ul
  li.nav-item:last-child {
  margin-right: 0;
}
.binduz-er-populer-news-sidebar-post.binduz-er-most-populer-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 14px;
  line-height: 24px;
}

/*===========================
    7.BINDUZ TRENDING TODAY css 
===========================*/
.binduz-er-trending-today-area {
  padding-top: 53px;
  padding-bottom: 21px;
  position: relative;
  z-index: 10;
}
.binduz-er-trending-today-area .binduz-er-bg-cover {
  left: 0;
  top: 0;
  background-image: url(../images/trending-today-bg.jpg);
  background-attachment: fixed;
}
.binduz-er-trending-today-area .binduz-er-bg-cover::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
}

.binduz-er-trending-today-topbar
  .binduz-er-trending-today-title
  .binduz-er-title {
  font-size: 30px;
  display: block;
  position: relative;
  color: #fff;
  margin-bottom: 30px;
}
.binduz-er-trending-today-topbar
  .binduz-er-trending-today-title
  .binduz-er-title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 4px;
  width: 75%;
  background: #f63a3a;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-trending-today-topbar
    .binduz-er-trending-today-title
    .binduz-er-title::before {
    width: 71%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-trending-today-topbar
    .binduz-er-trending-today-title
    .binduz-er-title::before {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .binduz-er-trending-today-topbar
    .binduz-er-trending-today-title
    .binduz-er-title::before {
    display: none;
  }
}

.binduz-er-trending-today-item {
  margin-bottom: 30px;
}
.binduz-er-trending-today-item .binduz-er-trending-news-list-box {
  display: block;
}
.binduz-er-trending-today-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb {
  min-width: 100%;
  height: auto;
  margin-right: 0;
  margin-bottom: 20px;
}
.binduz-er-trending-today-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb
  img {
  width: 100%;
  transition: all linear 0.5s;
}
.binduz-er-trending-today-item
  .binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}
.binduz-er-trending-today-item
  .binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  color: #999;
}
.binduz-er-trending-today-item
  .binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-trending-news-list-title
  .binduz-er-title
  a {
  color: #fff;
}
.binduz-er-trending-today-item
  .binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-trending-news-list-title
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-trending-today-item
  .binduz-er-trending-news-list-box:hover
  .binduz-er-thumb
  img {
  transform: scale(1.1);
}

/*===========================
    8.BINDUZ MAIN POST css 
===========================*/
.binduz-er-main-posts-area {
  padding-top: 53px;
  padding-bottom: 60px;
}

.binduz-er-main-posts-item {
  margin-bottom: 30px;
}
.binduz-er-main-posts-item .binduz-er-trending-news-list-box {
  display: block;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.binduz-er-main-posts-item .binduz-er-trending-news-list-box .binduz-er-thumb {
  min-width: 100%;
  height: auto;
  margin-right: 0;
  border-radius: 0;
}
.binduz-er-main-posts-item
  .binduz-er-trending-news-list-box
  .binduz-er-thumb
  img {
  width: 100%;
  transition: all linear 0.5s;
}
@media (max-width: 767px) {
  .binduz-er-main-posts-item
    .binduz-er-trending-news-list-box
    .binduz-er-thumb {
    margin-bottom: 0;
  }
}
.binduz-er-main-posts-item
  .binduz-er-trending-news-list-box
  .binduz-er-content {
  border: 2px solid #f4f4f4;
  border-top: 0px;
  padding-top: 30px;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 14px;
  border-radius: 0 0 10px 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-main-posts-item
    .binduz-er-trending-news-list-box
    .binduz-er-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }
}
.binduz-er-main-posts-item
  .binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-trending-news-list-title
  .binduz-er-title {
  margin-bottom: 5px;
}
.binduz-er-main-posts-item
  .binduz-er-trending-news-list-box
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date {
  margin-left: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-main-posts-item
    .binduz-er-trending-news-list-box
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 4px;
  }
}
.binduz-er-main-posts-item
  .binduz-er-trending-news-list-box:hover
  .binduz-er-thumb
  img {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-sidebar-about {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .binduz-er-sidebar-about {
    margin-top: 50px;
  }
}
.binduz-er-sidebar-about .binduz-er-sidebar-title {
  border-bottom: 2px solid #ffeaea;
  position: relative;
}
.binduz-er-sidebar-about .binduz-er-sidebar-title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid #ffeaea;
  border-right: 10px solid transparent;
}
.binduz-er-sidebar-about .binduz-er-sidebar-title .binduz-er-title {
  display: inline-block;
  background: #ffeaea;
  margin-bottom: 0;
  line-height: 30px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  color: #f63a3a;
}

.binduz-er-sidebar-about .binduz-er-sidebar-about-item {
  margin-top: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-about
    .binduz-er-sidebar-about-item
    .binduz-er-sidebar-about-user {
    display: block !important;
  }
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-thumb {
  margin-right: 20px;
  max-width: 80px;
  height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-about
    .binduz-er-sidebar-about-item
    .binduz-er-sidebar-about-user
    .binduz-er-thumb {
    margin-bottom: 10px;
  }
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-content
  .binduz-er-title {
  font-size: 20px;
  margin-bottom: 0px;
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-content
  span {
  font-size: 14px;
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-content
  ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-content
  ul
  li {
  display: inline-block;
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-content
  ul
  li
  a {
  color: #c3c3c3;
  margin-right: 20px;
  font-size: 14px;
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-content
  ul
  li
  a:hover {
  color: #f63a3a;
}
.binduz-er-sidebar-about
  .binduz-er-sidebar-about-item
  .binduz-er-sidebar-about-user
  .binduz-er-content
  ul
  li:last-child
  a {
  margin-right: 0;
}
.binduz-er-sidebar-about .binduz-er-sidebar-about-item .binduz-er-text {
  font-size: 14px;
  padding-top: 13px;
}
.binduz-er-sidebar-about .binduz-er-sidebar-about-item .binduz-er-text a {
  font-size: 14px;
  font-weight: 700;
  background: #f63a3a;
  border-color: #f63a3a;
}
.binduz-er-sidebar-about .binduz-er-sidebar-about-item .binduz-er-text a:hover {
  background: #12238c;
  border-color: #12238c;
}

.binduz-er-sidebar-latest-post {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-sidebar-latest-post {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-sidebar-latest-post {
    margin-bottom: 30px;
  }
}
.binduz-er-sidebar-latest-post .binduz-er-sidebar-title {
  border-bottom: 2px solid #ffeaea;
  position: relative;
}
.binduz-er-sidebar-latest-post .binduz-er-sidebar-title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid #ffeaea;
  border-right: 10px solid transparent;
}
.binduz-er-sidebar-latest-post .binduz-er-sidebar-title .binduz-er-title {
  display: inline-block;
  background: #ffeaea;
  margin-bottom: 0;
  line-height: 30px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  color: #f63a3a;
}

.binduz-er-sidebar-latest-post-box {
  margin-top: 35px;
}
.binduz-er-sidebar-latest-post-box .binduz-er-sidebar-latest-post-item {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-latest-post-box .binduz-er-sidebar-latest-post-item {
    display: block;
  }
}
.binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-thumb {
  max-width: 80px;
  height: 80px;
  margin-right: 30px;
  border-radius: 10px;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-sidebar-latest-post-box
    .binduz-er-sidebar-latest-post-item
    .binduz-er-thumb {
    margin-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-latest-post-box
    .binduz-er-sidebar-latest-post-item
    .binduz-er-thumb {
    margin-bottom: 10px;
  }
}
.binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-content
  span {
  font-size: 14px;
  color: #666;
}
.binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-sidebar-latest-post-box
    .binduz-er-sidebar-latest-post-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 15px;
    line-height: 25px;
  }
}
.binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-content
  .binduz-er-title
  a {
  color: #000;
}
.binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}

.binduz-er-sidebar-add-box {
  background-image: url(../images/space-bg-2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 10;
  padding: 60px 45px 60px;
  border-radius: 10px;
  overflow: hidden;
  color: #c8c8c8;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-sidebar-add-box {
    padding: 30px 20px 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-add-box {
    padding: 30px 20px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-sidebar-add-box {
    width: 50%;
  }
}
.binduz-er-sidebar-add-box::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
}
.binduz-er-sidebar-add-box p {
  margin-top: 22px;
  margin-bottom: 22px;
}
.binduz-er-sidebar-add-box > a {
  background: #f63a3a;
  border-color: #f63a3a;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-sidebar-add-box > a {
    padding: 0 20px;
  }
}
.binduz-er-sidebar-add-box > a:hover {
  background: #12238c;
  border-color: #12238c;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-social-share-box {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-social-share-box {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .binduz-er-social-share-box {
    display: none !important;
  }
}

.binduz-er-social-share-item {
  background-image: url(../images/instagram-bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 60px;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-social-share-item {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-social-share-item {
    width: 33.3%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.binduz-er-social-share-item a i {
  display: block;
  font-size: 50px;
  color: #fff;
}
.binduz-er-social-share-item a span {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-top: 7px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-social-share-item a span {
    font-size: 24px;
  }
}
.binduz-er-social-share-item a span span {
  font-weight: 300;
}
.binduz-er-social-share-item.binduz-facebook {
  background-image: none;
  background-color: #4267b2;
}
.binduz-er-social-share-item.binduz-twitter {
  background-image: none;
  background-color: #1da1f2;
}
.binduz-er-social-share-item.binduz-pinterest {
  background-image: none;
  background-color: #e60023;
}
.binduz-er-social-share-item.binduz-dribbble {
  background-image: none;
  background-color: #ea4c89;
}

/*===========================
    9.BINDUZ FOOTER css 
===========================*/
.binduz-er-footer-area {
  padding-top: 75px;
  padding-bottom: 60px;
  background: #000;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.binduz-er-footer-area::before {
  position: absolute;
  z-index: -1;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  background: #111111;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-footer-area::before {
    width: 23%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-area::before {
    width: 26%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-area::before {
    width: 0%;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-area::before {
    width: 0%;
  }
}

.binduz-er-footer-widget-style-1 {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .binduz-er-footer-widget-style-1 {
    margin-bottom: 30px;
    margin-right: 0;
  }
}

.binduz-er-footer-widget-style-3 {
  margin-right: 15px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-footer-widget-style-3 {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-widget-style-3 {
    margin-bottom: 30px;
    margin-right: 0;
  }
}
.binduz-er-footer-widget-style-3
  .binduz-er-footer-title
  .binduz-er-title::before {
  width: 56%;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-widget-style-3
    .binduz-er-footer-title
    .binduz-er-title::before {
    width: 34%;
  }
}

.binduz-er-footer-title .binduz-er-title {
  font-size: 18px;
  color: #fff;
  position: relative;
  margin-bottom: 30px;
}
.binduz-er-footer-title .binduz-er-title::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 65%;
  background: #f63a3a;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-title .binduz-er-title::before {
    width: 40%;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-title .binduz-er-title::before {
    display: none;
  }
}

.binduz-er-footer-menu-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.binduz-er-footer-menu-list ul {
  margin: 0 50px 0 0;
  padding: 0;
  list-style-type: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-footer-menu-list ul {
    margin: 0 15px 0 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-menu-list ul {
    margin: 0 15px 0 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-menu-list ul {
    margin: 0 30px 0 0;
  }
}
.binduz-er-footer-menu-list ul li a {
  color: #999;
  margin-bottom: 16px;
  display: inline-block;
}
.binduz-er-footer-menu-list ul li a:hover {
  color: #f63a3a;
}
.binduz-er-footer-menu-list ul:last-child {
  margin-right: 0;
}

.binduz-er-footer-widget-form .binduz-er-input-box {
  position: relative;
  margin-bottom: 15px;
}
.binduz-er-footer-widget-form .binduz-er-input-box > i {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #686868;
}
.binduz-er-footer-widget-form .binduz-er-input-box input {
  width: 100%;
  background: #0d0d0d;
  color: #686868;
  border: 0;
  height: 60px;
  border-radius: 10px;
  font-size: 14px;
  padding-left: 48px;
}
.binduz-er-footer-widget-form .binduz-er-input-box input::placeholder {
  color: #686868;
  opacity: 1;
}
.binduz-er-footer-widget-form .binduz-er-input-box button {
  width: 100%;
  background: #f63a3a;
  color: #fff;
  line-height: 60px;
  border-color: #f63a3a;
  border-radius: 10px;
  border: 0;
}
.binduz-er-footer-widget-form .binduz-er-input-box button i {
  padding-right: 6px;
}

.binduz-er-footer-widget-feeds
  .binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-title {
  font-size: 18px;
  line-height: 28px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-footer-widget-feeds
    .binduz-er-sidebar-latest-post-box
    .binduz-er-sidebar-latest-post-item
    .binduz-er-title {
    font-size: 15px;
    line-height: 25px;
  }
}
.binduz-er-footer-widget-feeds
  .binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-title
  a {
  color: #fff;
}

.binduz-er-footer-widget-info {
  margin-left: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-widget-info {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-widget-info {
    margin-left: 0;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-widget-info {
    margin-left: 0;
    margin-top: 30px;
  }
}
.binduz-er-footer-widget-info .binduz-er-text {
  padding-top: 22px;
  font-size: 14px;
}
.binduz-er-footer-widget-info .binduz-er-social {
  padding-top: 9px;
}
.binduz-er-footer-widget-info .binduz-er-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-footer-widget-info .binduz-er-social ul li {
  display: inline-block;
}
.binduz-er-footer-widget-info .binduz-er-social ul li a {
  font-size: 14px;
  color: #fff;
  display: inline-block;
  background: #3b5999;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  margin-right: 4px;
}
.binduz-er-footer-widget-info .binduz-er-social ul li:nth-child(2) a {
  background: #55acee;
}
.binduz-er-footer-widget-info .binduz-er-social ul li:nth-child(3) a {
  background: #0056ff;
}
.binduz-er-footer-widget-info .binduz-er-social ul li:nth-child(4) a {
  background: #cd201f;
}

.binduz-er-footer-copyright-area {
  background: #111111;
  padding: 25px 0;
  position: relative;
}
.binduz-er-footer-copyright-area .binduz-er-footer-logo {
  position: absolute;
  left: 0;
  bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-footer-copyright-area .binduz-er-footer-logo {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-copyright-area .binduz-er-footer-logo {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-copyright-area .binduz-er-footer-logo {
    display: none;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-copyright-area .binduz-er-footer-logo {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-copyright-area .binduz-er-copyright-text {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-copyright-area .binduz-er-copyright-text {
    text-align: center;
  }
}
.binduz-er-footer-copyright-area .binduz-er-copyright-text p {
  color: #999;
  margin-bottom: 0;
}
.binduz-er-footer-copyright-area .binduz-er-copyright-text p span {
  font-weight: 700;
  color: #fff;
}
.binduz-er-footer-copyright-area.binduz-er-footer-copyright-area-2 {
  background-color: transparent;
  padding: 0;
}
.binduz-er-footer-copyright-area.binduz-er-footer-copyright-area-2
  .binduz-er-footer-copyright-box {
  background: #004578;
  padding: 25px 40px;
}
.binduz-er-footer-copyright-area.binduz-er-footer-copyright-area-2
  .binduz-er-footer-copyright-box
  .binduz-er-copyright-text
  p {
  color: #9eb9ce;
}
.binduz-er-footer-copyright-area.binduz-er-footer-copyright-area-2
  .binduz-er-footer-copyright-box
  .binduz-er-copyright-menu
  ul
  li
  a {
  color: #9eb9ce;
}
.binduz-er-footer-copyright-area.binduz-er-footer-copyright-area-2
  .binduz-er-footer-copyright-box
  .binduz-er-copyright-menu
  ul
  li
  a:hover {
  color: #fff;
}
.binduz-er-footer-copyright-area.binduz-er-footer-copyright-area-4 {
  background: #222;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-copyright-menu {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .binduz-er-copyright-menu {
    text-align: center;
  }
}
.binduz-er-copyright-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-copyright-menu ul {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-copyright-menu ul {
    margin-top: 10px;
  }
}
.binduz-er-copyright-menu ul li {
  display: inline-block;
  margin-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-copyright-menu ul li {
    margin-left: 26px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-copyright-menu ul li {
    margin: 4px 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-copyright-menu ul li {
    margin: 4px 10px;
  }
}
.binduz-er-copyright-menu ul li a {
  color: #999;
}
.binduz-er-copyright-menu ul li a:hover {
  color: #f63a3a;
}

.binduz-er-back-to-top {
  position: fixed;
  right: -72px;
  bottom: 220px;
  background: #f63a3a;
  color: #fff;
  cursor: pointer;
  z-index: 9;
  border-radius: 10px 10px 0 0;
  transform: rotate(-90deg);
  display: none;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-back-to-top {
    right: -88px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-back-to-top {
    right: -88px;
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .binduz-er-back-to-top {
    right: -88px;
    bottom: 100px;
  }
}
.binduz-er-back-to-top p {
  margin-bottom: 0;
  line-height: 60px;
  padding: 0 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-back-to-top p {
    line-height: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-back-to-top p {
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-back-to-top p {
    line-height: 30px;
  }
}
.binduz-er-back-to-top p i {
  padding-left: 6px;
}

.binduz-er-footer-2-area .binduz-er-footer-box {
  background: #004e87;
  padding-top: 60px;
  padding-bottom: 60px;
}

.binduz-er-footer-box {
  position: relative;
  z-index: 10;
}
.binduz-er-footer-box::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 32%;
  background: #004578;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-box::before {
    width: 31%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-box::before {
    width: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-box::before {
    width: 0;
  }
}

.binduz-er-footer-about {
  padding: 0 70px;
  color: #9eb9ce;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-footer-about {
    padding: 0 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-about {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-about {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-footer-about {
    padding: 0 70px;
  }
}
.binduz-er-footer-about .binduz-er-logo {
  margin-bottom: 23px;
}
.binduz-er-footer-about ul {
  margin: 0;
  padding: 18px 0 0;
  list-style-type: none;
}
.binduz-er-footer-about ul li {
  display: inline-block;
}
.binduz-er-footer-about ul li a {
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  display: inline-block;
  background: #004e87;
  margin: 0 4px;
  font-size: 18px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-about ul li a {
    background: #004578;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-about ul li a {
    background: #004578;
  }
}
.binduz-er-footer-about ul li a:hover {
  background: #fff;
  color: #004e87;
}
.binduz-er-footer-about.binduz-er-footer-about-4 {
  background-color: transparent;
  padding: 0;
  color: #999;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-about.binduz-er-footer-about-4 {
    text-align: center;
    padding: 0 200px;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-about.binduz-er-footer-about-4 {
    text-align: center;
    padding: 0 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-footer-about.binduz-er-footer-about-4 {
    text-align: center;
    padding: 0 100px;
  }
}
.binduz-er-footer-about.binduz-er-footer-about-4 ul li a {
  background: #1f1f1f;
  margin-bottom: 10px;
}
.binduz-er-footer-about.binduz-er-footer-about-4 ul li a:hover {
  background: #e74d58;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-gallery {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-gallery {
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-gallery {
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
}
.binduz-er-footer-gallery .binduz-er-footer-title .binduz-er-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 38px;
}
.binduz-er-footer-gallery .binduz-er-footer-title .binduz-er-title::before {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-gallery .binduz-er-footer-gallery-widget {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-gallery .binduz-er-footer-gallery-widget {
    justify-content: center;
  }
}
.binduz-er-footer-gallery .binduz-er-footer-gallery-widget .binduz-er-item {
  margin-right: 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .binduz-er-footer-gallery .binduz-er-footer-gallery-widget .binduz-er-item {
    margin: 0 10px 20px;
  }
}

.binduz-er-footer-navigation {
  margin-left: -30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-footer-navigation {
    margin-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-navigation {
    margin-left: -20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-navigation {
    text-align: center;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-navigation {
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
}
.binduz-er-footer-navigation .binduz-er-footer-title .binduz-er-title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 38px;
}
.binduz-er-footer-navigation .binduz-er-footer-title .binduz-er-title::before {
  display: none;
}
.binduz-er-footer-navigation .binduz-er-footer-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-footer-navigation .binduz-er-footer-list {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-navigation .binduz-er-footer-list {
    justify-content: center;
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-footer-navigation .binduz-er-footer-list {
    justify-content: center;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
}
.binduz-er-footer-navigation .binduz-er-footer-list ul {
  margin: 0 50px 0 0;
  padding: 0;
  list-style-type: none;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-footer-navigation .binduz-er-footer-list ul {
    margin: 0 20px 0 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-footer-navigation .binduz-er-footer-list ul {
    margin: 0;
  }
}
.binduz-er-footer-navigation .binduz-er-footer-list ul:last-child {
  margin-right: 0;
}
.binduz-er-footer-navigation .binduz-er-footer-list ul li {
  padding-bottom: 15px;
}
.binduz-er-footer-navigation .binduz-er-footer-list ul li a {
  color: #aac0d0;
}
.binduz-er-footer-navigation.binduz-er-footer-navigation-4 ul li a {
  color: #999;
}
.binduz-er-footer-navigation.binduz-er-footer-navigation-4 ul li a:hover {
  color: #f63a3a;
}

.binduz-er-footer-3-area {
  background: #000;
  padding-top: 27px;
  padding-bottom: 47px;
}

.binduz-er-footer-navigation-widget .binduz-er-footer-title .binduz-er-title {
  color: #fff;
  font-size: 18px;
  margin-bottom: 30px;
}
.binduz-er-footer-navigation-widget
  .binduz-er-footer-title
  .binduz-er-title::before {
  display: none;
}
.binduz-er-footer-navigation-widget .binduz-er-navigarion-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-footer-navigation-widget .binduz-er-navigarion-list ul li a {
  color: #999;
  display: inline-block;
  margin-bottom: 10px;
}
.binduz-er-footer-navigation-widget .binduz-er-navigarion-list ul li a:hover {
  color: #f63a3a;
}

.binduz-er-footer-area-4 {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #111111;
}

/*===========================
    10.BINDUZ NEWS CATEGORIES css 
===========================*/
.binduz-er-news-categories-tab {
  margin-top: 40px;
  margin-bottom: 40px;
}
.binduz-er-news-categories-tab ul li a {
  background: #fff;
  color: #777777;
  border-radius: 0 !important;
  margin: 0 8px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-news-categories-tab ul li a {
    margin: 0 6px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-categories-tab ul li a {
    margin: 0 2px 4px;
  }
}
.binduz-er-news-categories-tab ul li a.active {
  background-color: #004e87 !important;
}

.binduz-er-news-categories-box .binduz-er-news-categories-item {
  background: #fff;
  padding: 30px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-categories-box .binduz-er-news-categories-item {
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-news-categories-box .binduz-er-news-categories-item {
    padding: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-categories-box .binduz-er-news-categories-item {
    display: block;
  }
}
.binduz-er-news-categories-box
  .binduz-er-news-categories-item
  .binduz-er-thumb {
  min-width: 120px;
  height: 100px;
  margin-right: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-categories-box
    .binduz-er-news-categories-item
    .binduz-er-thumb {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-categories-box
    .binduz-er-news-categories-item
    .binduz-er-thumb {
    margin-bottom: 10px;
  }
}
.binduz-er-news-categories-box
  .binduz-er-news-categories-item
  .binduz-er-content
  .binduz-er-meta-date
  span {
  color: #666;
  font-size: 14px;
}
.binduz-er-news-categories-box
  .binduz-er-news-categories-item
  .binduz-er-content
  .binduz-er-news-categories-title
  .binduz-er-title {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-news-categories-box
    .binduz-er-news-categories-item
    .binduz-er-content
    .binduz-er-news-categories-title
    .binduz-er-title {
    font-size: 16px;
    line-height: 26px;
  }
}
.binduz-er-news-categories-box
  .binduz-er-news-categories-item
  .binduz-er-content
  .binduz-er-news-categories-title
  .binduz-er-title
  a {
  color: #000;
}
.binduz-er-news-categories-box
  .binduz-er-news-categories-item
  .binduz-er-content
  .binduz-er-news-categories-title
  .binduz-er-title
  a:hover {
  color: #004e87;
}

.binduz-er-news-slider-box {
  background: #fff;
}
.binduz-er-news-slider-box .binduz-er-news-slider-item .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-size: 18px;
}
.binduz-er-news-slider-box .binduz-er-news-slider-item .slick-arrow:hover {
  background: #004e87;
}
.binduz-er-news-slider-box .binduz-er-news-slider-item .slick-arrow.prev {
  left: 0;
}
.binduz-er-news-slider-box .binduz-er-news-slider-item .slick-arrow.next {
  right: 0;
}

.binduz-er-news-slider-content-slider .binduz-er-item {
  padding-left: 80px;
  padding-right: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-news-slider-content-slider .binduz-er-item {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-slider-content-slider .binduz-er-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-news-slider-content-slider .binduz-er-item {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-slider-content-slider .binduz-er-item {
    padding: 30px;
  }
}
.binduz-er-news-slider-content-slider .binduz-er-item .binduz-er-meta-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media (max-width: 767px) {
  .binduz-er-news-slider-content-slider .binduz-er-item .binduz-er-meta-item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-slider-content-slider .binduz-er-item .binduz-er-meta-item {
    display: flex;
  }
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #e6f4ff;
  display: inline-block;
  line-height: 30px;
  padding: 0 15px;
  color: #004e87;
  font-weight: 700;
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  color: #777777;
  margin-left: 20px;
  display: inline-block;
}
@media (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    margin-left: 20px;
    margin-top: 0;
  }
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-item
  .binduz-er-meta-date
  span
  i {
  padding-right: 6px;
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-news-slider-content
  .binduz-er-title {
  font-size: 48px;
  line-height: 58px;
  padding-top: 18px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-news-slider-content
    .binduz-er-title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-news-slider-content
    .binduz-er-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-news-slider-content
    .binduz-er-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-news-slider-content
    .binduz-er-title {
    font-size: 23px;
    line-height: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-news-slider-content
    .binduz-er-title {
    font-size: 40px;
    line-height: 50px;
  }
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-news-slider-content
  .binduz-er-title
  a {
  color: #000;
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-news-slider-content
  .binduz-er-title
  a:hover {
  color: #004e87;
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-news-slider-content
  p {
  font-size: 18px;
  line-height: 30px;
}
.binduz-er-news-slider-content-slider .binduz-er-item .binduz-er-meta-author {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  padding-top: 20px;
}
@media (max-width: 767px) {
  .binduz-er-news-slider-content-slider .binduz-er-item .binduz-er-meta-author {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-slider-content-slider .binduz-er-item .binduz-er-meta-author {
    display: flex;
  }
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-author
  .binduz-er-author
  img {
  margin-right: 15px;
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-author
  .binduz-er-author
  span {
  color: #777777;
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-author
  .binduz-er-author
  span
  span {
  color: #000;
  font-weight: 700;
}
@media (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-meta-author
    .binduz-er-meta-list {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-meta-author
    .binduz-er-meta-list {
    margin-top: 0px;
  }
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-author
  .binduz-er-meta-list
  ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-news-slider-content-slider
  .binduz-er-item
  .binduz-er-meta-author
  .binduz-er-meta-list
  ul
  li {
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  line-height: 14px;
  border-left: 1px solid #dddddd;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    margin-left: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    border-left: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-slider-content-slider
    .binduz-er-item
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    border-left: 1px solid #dddddd;
    margin-left: 15px;
  }
}

.binduz-er-news-slider-box:hover
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #004e87;
  color: #fff;
}

/*===========================
    11.BINDUZ TOP NEWS css 
===========================*/
.binduz-er-top-news-area {
  padding-bottom: 60px;
}
.binduz-er-top-news-area .binduz-er-social-list .binduz-er-list {
  margin-top: 35px;
}
.binduz-er-top-news-area .binduz-er-social-list .binduz-er-list a {
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 0px;
  padding: 10px 30px 10px 10px;
  align-items: center;
  margin-bottom: 8px;
}
.binduz-er-top-news-area .binduz-er-social-list .binduz-er-list a span {
  font-size: 14px;
  color: #000;
}
.binduz-er-top-news-area .binduz-er-social-list .binduz-er-list a span span {
  font-weight: 700;
}
.binduz-er-top-news-area .binduz-er-social-list .binduz-er-list a span i {
  height: 40px;
  width: 40px;
  background: #3b5999;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 0px;
  margin-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-top-news-area .binduz-er-social-list .binduz-er-list a span i {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-top-news-area .binduz-er-social-list .binduz-er-list a span i {
    margin-right: 5px;
  }
}
.binduz-er-top-news-area
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(2)
  span
  i {
  background: #55acee;
}
.binduz-er-top-news-area
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(3)
  span
  i {
  background: #0057ff;
}
.binduz-er-top-news-area
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(4)
  span
  i {
  background: #cd201f;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-top-news-title {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-title {
    margin-top: 30px;
  }
}
.binduz-er-top-news-title .binduz-er-title {
  font-size: 24px;
  position: relative;
  padding-top: 21px;
  margin-bottom: 33px;
}
.binduz-er-top-news-title .binduz-er-title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 4px;
  width: 260px;
  background: #004e87;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-top-news-title .binduz-er-title::before {
    width: 235px;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-title .binduz-er-title::before {
    width: 200px;
  }
}

.binduz-er-top-news-item {
  background: #fff;
  padding-top: 24px;
  padding-bottom: 26px;
  padding-right: 45px;
  position: relative;
  padding-left: 54px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .binduz-er-top-news-item {
    padding-right: 30px;
  }
}
.binduz-er-top-news-item:last-child {
  margin-bottom: 0;
}
.binduz-er-top-news-item > span {
  position: absolute;
  left: 0;
  top: 30px;
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  background: #004e87;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}
.binduz-er-top-news-item .binduz-er-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  text-transform: inherit;
  margin-bottom: 6px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-top-news-item .binduz-er-title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-top-news-item .binduz-er-title {
    font-size: 15px;
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-item .binduz-er-title {
    font-size: 15px;
    line-height: 25px;
  }
}
.binduz-er-top-news-item .binduz-er-title a {
  color: #000;
}
.binduz-er-top-news-item .binduz-er-title a:hover {
  color: #004e87;
}
.binduz-er-top-news-item .binduz-er-meta-date span {
  font-size: 14px;
}

.binduz-er-news-viewed-most-slide .slick-arrow {
  position: absolute;
  top: -82px;
  cursor: pointer;
  height: 60px;
  width: 60px;
  background: #fff;
  text-align: center;
  line-height: 60px;
  font-size: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-news-viewed-most-slide .slick-arrow:hover {
  background: #004e87;
  color: #fff;
}
.binduz-er-news-viewed-most-slide .slick-arrow.prev {
  right: 61px;
}
.binduz-er-news-viewed-most-slide .slick-arrow.next {
  right: 0;
}

.binduz-er-news-viewed-most {
  background: #fff;
}
.binduz-er-news-viewed-most .binduz-er-thumb img {
  transition: all linear 0.5s;
}
.binduz-er-news-viewed-most .binduz-er-content {
  padding: 40px 30px;
  text-align: center;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-viewed-most .binduz-er-content {
    padding: 20px 20px;
  }
}
.binduz-er-news-viewed-most .binduz-er-content .binduz-er-meta-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .binduz-er-news-viewed-most .binduz-er-content .binduz-er-meta-item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-viewed-most .binduz-er-content .binduz-er-meta-item {
    display: flex;
  }
}
.binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #e6f4ff;
  display: inline-block;
  line-height: 30px;
  padding: 0 15px;
  color: #004e87;
  font-weight: 700;
}
@media (max-width: 767px) {
  .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-categories
    a {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-categories
    a {
    margin-bottom: 0px;
  }
}
.binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  color: #777777;
  margin-left: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-viewed-most
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    font-size: 12px;
    margin-left: 5px;
  }
}
.binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span
  i {
  padding-right: 6px;
}
.binduz-er-news-viewed-most .binduz-er-content .binduz-er-title {
  font-size: 24px;
  line-height: 34px;
  padding-top: 13px;
  margin-bottom: 19px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-news-viewed-most .binduz-er-content .binduz-er-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-news-viewed-most .binduz-er-content .binduz-er-title {
    font-size: 17px;
    line-height: 27px;
  }
}
@media (max-width: 767px) {
  .binduz-er-news-viewed-most .binduz-er-content .binduz-er-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-news-viewed-most .binduz-er-content .binduz-er-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.binduz-er-news-viewed-most .binduz-er-content .binduz-er-title a {
  color: #000;
}
.binduz-er-news-viewed-most .binduz-er-content .binduz-er-title a:hover {
  color: #004e87;
}
.binduz-er-news-viewed-most .binduz-er-content .binduz-er-meta-author > span {
  margin-left: 20px;
}
.binduz-er-news-viewed-most
  .binduz-er-content
  .binduz-er-meta-author
  > span
  span {
  font-weight: 700;
  color: #000;
}
.binduz-er-news-viewed-most:hover .binduz-er-thumb img {
  transform: scale(1.1);
}
.binduz-er-news-viewed-most:hover
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #004e87;
  color: #fff;
}

.binduz-er-top-news-faq {
  background: #fff;
  padding: 35px;
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-top-news-faq {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-faq {
    margin-top: 30px;
  }
}
.binduz-er-top-news-faq .binduz-er-client-thumb {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-top-news-faq .binduz-er-client-thumb img {
    width: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-top-news-faq .binduz-er-client-thumb img {
    width: 85px;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-faq .binduz-er-client-thumb img {
    width: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-top-news-faq .binduz-er-client-thumb img {
    width: auto;
  }
}
.binduz-er-top-news-faq .binduz-er-title {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-top-news-faq .binduz-er-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-faq .binduz-er-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-top-news-faq .binduz-er-title {
    font-size: 24px;
    line-height: 34px;
  }
}
.binduz-er-top-news-faq .binduz-er-meta-author span span {
  font-weight: 700;
  color: #004e87;
}
.binduz-er-top-news-faq .binduz-er-answare {
  border-top: 1px solid #e4e4e4;
  padding-top: 18px;
  margin-top: 20px;
  width: 220px;
}

.binduz-er-top-news-2-item {
  margin-left: -60px;
  margin-right: -60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-top-news-2-item {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-2-item {
    margin-left: 0;
    margin-right: 0;
  }
}
.binduz-er-top-news-2-item .binduz-er-trending-news-item {
  background-image: url(../images/top-news-item-2.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 404px;
  border-radius: 0;
  transition: all linear 0.5s;
}
.binduz-er-top-news-2-item
  .binduz-er-trending-news-item
  .binduz-er-trending-news-overlay
  .binduz-er-trending-news-meta
  .binduz-er-meta-categories
  a {
  background: #e74d58;
  border-radius: 0;
}
.binduz-er-top-news-2-item .binduz-er-trending-news-item:hover {
  background-size: 110% 110%;
}
.binduz-er-top-news-2-item.binduz-er-top-news-2-1-item {
  margin-right: 0;
  margin-left: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-top-news-2-item.binduz-er-top-news-2-1-item {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-top-news-2-item.binduz-er-top-news-2-1-item {
    margin-left: 0;
  }
}
.binduz-er-top-news-2-item.binduz-er-top-news-2-1-item
  .binduz-er-trending-news-item {
  background-image: url(../images/top-news-item-3.jpg);
  height: 188px;
}
.binduz-er-top-news-2-item.binduz-er-top-news-2-1-item
  .binduz-er-trending-news-item.item-2 {
  background-image: url(../images/populer-news-9.jpg);
}

/*===========================
    12.BINDUZ FAVORITES CATEGORIES css 
===========================*/
.binduz-er-favorites-categories-area {
  padding-bottom: 60px;
}

.binduz-er-favorites-categories-box {
  background: #fff;
  padding: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-favorites-categories-box {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-favorites-categories-box {
    padding: 30px;
  }
}
.binduz-er-favorites-categories-box .binduz-er-favorites-categories-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-favorites-categories-box .binduz-er-favorites-categories-list {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-favorites-categories-box .binduz-er-favorites-categories-list {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .binduz-er-favorites-categories-box .binduz-er-favorites-categories-list {
    flex-wrap: wrap;
  }
}
.binduz-er-favorites-categories-box
  .binduz-er-favorites-categories-list
  .binduz-er-item {
  margin-right: 40px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-favorites-categories-box
    .binduz-er-favorites-categories-list
    .binduz-er-item {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-favorites-categories-box
    .binduz-er-favorites-categories-list
    .binduz-er-item {
    margin-right: 5px;
    margin-bottom: 15px;
    width: 16%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-favorites-categories-box
    .binduz-er-favorites-categories-list
    .binduz-er-item {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .binduz-er-favorites-categories-box
    .binduz-er-favorites-categories-list
    .binduz-er-item {
    margin-right: 13px;
    margin-bottom: 15px;
  }
}
.binduz-er-favorites-categories-box
  .binduz-er-favorites-categories-list
  .binduz-er-item:last-child {
  margin-right: 0;
}
.binduz-er-favorites-categories-box
  .binduz-er-favorites-categories-list
  .binduz-er-item
  span {
  display: block;
  font-size: 14px;
  color: #000;
  padding-top: 12px;
}
.binduz-er-favorites-categories-box
  .binduz-er-favorites-categories-list
  .binduz-er-item:last-child {
  margin-right: 0;
}

/*===========================
    13.BINDUZ LATEST NEWS css 
===========================*/
.binduz-er-latest-news-area {
  padding-bottom: 60px;
}

.binduz-er-latest-news-item {
  background: #fff;
  padding: 30px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-latest-news-item {
    display: block;
  }
}
@media (max-width: 767px) {
  .binduz-er-latest-news-item {
    display: block;
  }
}
.binduz-er-latest-news-item:last-child {
  margin-bottom: 0;
}
.binduz-er-latest-news-item .binduz-er-thumb {
  min-width: 240px;
  max-width: 240px;
  margin-right: 40px;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-latest-news-item .binduz-er-thumb {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-latest-news-item .binduz-er-thumb {
    margin-right: 0px;
    margin-bottom: 20px;
    min-width: 100%;
  }
}
.binduz-er-latest-news-item .binduz-er-thumb img {
  width: 100%;
  transition: all linear 0.5s;
}
.binduz-er-latest-news-item .binduz-er-content .binduz-er-meta-categories a {
  display: inline-block;
  background: #e6f4ff;
  line-height: 30px;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 700;
  color: #004e87;
}
.binduz-er-latest-news-item .binduz-er-content .binduz-er-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  padding-top: 11px;
}
@media (max-width: 767px) {
  .binduz-er-latest-news-item .binduz-er-content .binduz-er-title {
    font-size: 16px;
    line-height: 26px;
  }
}
.binduz-er-latest-news-item .binduz-er-content .binduz-er-title a {
  color: #000;
}
.binduz-er-latest-news-item .binduz-er-content .binduz-er-title a:hover {
  color: #004e87;
}
.binduz-er-latest-news-item .binduz-er-content .binduz-er-meta-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media (max-width: 767px) {
  .binduz-er-latest-news-item .binduz-er-content .binduz-er-meta-item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-latest-news-item .binduz-er-content .binduz-er-meta-item {
    display: flex;
  }
}
.binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-author {
  margin-right: 30px;
}
.binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-author
  span
  span {
  color: #004e87;
}
@media (max-width: 767px) {
  .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-top: 10px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-top: 0px;
    margin-left: 10px;
  }
}
.binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span
  i {
  padding-right: 5px;
}
.binduz-er-latest-news-item:hover .binduz-er-thumb img {
  transform: scale(1.1);
}
.binduz-er-latest-news-item:hover
  .binduz-er-content
  .binduz-er-meta-categories
  a {
  background: #004e87;
  color: #fff;
}

.binduz-er-video-post .binduz-er-latest-news-item {
  display: block;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.binduz-er-video-post .binduz-er-latest-news-item .binduz-er-thumb {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;
  position: relative;
  min-width: 100%;
  max-width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-video-post .binduz-er-latest-news-item .binduz-er-thumb {
    min-width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post .binduz-er-latest-news-item .binduz-er-thumb {
    min-width: 100%;
  }
}
.binduz-er-video-post .binduz-er-latest-news-item .binduz-er-thumb img {
  width: 100%;
}
.binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-thumb
  .binduz-er-play {
  position: absolute;
  right: 20px;
  top: 20px;
}
.binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-thumb
  .binduz-er-play
  a {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #004e87;
  color: #fff;
  border-radius: 50%;
}
.binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-date {
  margin-left: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-date {
    margin-left: 6px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-date {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-date {
    margin-left: 10px;
  }
}
.binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-date
  span {
  font-size: 14px;
}
.binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-date
  span
  i {
  padding-right: 6px;
}
.binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 18px;
  }
}
.binduz-er-video-post.binduz-er-recently-viewed-item
  .binduz-er-latest-news-item {
  padding: 0;
}
.binduz-er-video-post.binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .binduz-er-video-post.binduz-er-recently-viewed-item
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item {
    display: block;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post.binduz-er-recently-viewed-item
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 0px;
    margin-top: 10px;
  }
}
.binduz-er-video-post.binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 19px;
  line-height: 30px;
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post.binduz-er-recently-viewed-item
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post.binduz-er-recently-viewed-item
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 18px;
  }
}
.binduz-er-video-post.binduz-er-newsletter-post .binduz-er-latest-news-item {
  padding: 0;
}
.binduz-er-video-post.binduz-er-newsletter-post
  .binduz-er-latest-news-item
  .binduz-er-thumb {
  margin: 0;
}
.binduz-er-video-post.binduz-er-newsletter-post
  .binduz-er-latest-news-item
  .binduz-er-content {
  padding: 40px 40px 36px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post.binduz-er-newsletter-post
    .binduz-er-latest-news-item
    .binduz-er-content {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post.binduz-er-newsletter-post
    .binduz-er-latest-news-item
    .binduz-er-content {
    padding: 20px;
  }
}
.binduz-er-video-post.binduz-er-newsletter-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item {
  display: block;
}
.binduz-er-video-post.binduz-er-newsletter-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date {
  margin-left: 0;
  margin-top: 10px;
}
.binduz-er-video-post.binduz-er-newsletter-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-video-post.binduz-er-newsletter-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post.binduz-er-newsletter-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post.binduz-er-newsletter-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 17px;
    line-height: 27px;
  }
}
.binduz-er-video-post.binduz-er-top-news-2-slider {
  margin-right: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider {
    margin-right: 0;
  }
}
.binduz-er-video-post.binduz-er-top-news-2-slider .binduz-er-latest-news-item {
  padding: 0;
}
.binduz-er-video-post.binduz-er-top-news-2-slider
  .binduz-er-latest-news-item
  .binduz-er-content {
  padding: 20px 40px 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider
    .binduz-er-latest-news-item
    .binduz-er-content {
    padding: 10px 30px 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider
    .binduz-er-latest-news-item
    .binduz-er-content {
    padding: 10px 20px 25px;
  }
}
@media (max-width: 767px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider
    .binduz-er-latest-news-item
    .binduz-er-content {
    padding: 0px 20px 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item {
    display: block;
  }
}
.binduz-er-video-post.binduz-er-top-news-2-slider
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #e74d58;
  color: #fff;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 0;
    margin-top: 10px;
  }
}
.binduz-er-video-post.binduz-er-top-news-2-slider
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 24px;
  line-height: 34px;
  text-transform: inherit;
  letter-spacing: -0.1px;
  margin-bottom: 0;
}
.binduz-er-video-post.binduz-er-top-news-2-slider
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-video-post.binduz-er-top-news-2-slider
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 18px;
    line-height: 28px;
  }
}
.binduz-er-video-post.binduz-er-top-news-2-slider .slick-arrow {
  position: absolute;
  top: 85px;
  cursor: pointer;
  z-index: 9;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-video-post.binduz-er-top-news-2-slider .slick-arrow:hover {
  background: #e74d58;
}
.binduz-er-video-post.binduz-er-top-news-2-slider .slick-arrow.prev {
  left: 0;
}
.binduz-er-video-post.binduz-er-top-news-2-slider .slick-arrow.next {
  right: -1px;
}

.binduz-er-recently-viewed-area {
  padding-bottom: 60px;
}
.binduz-er-recently-viewed-area .binduz-er-recently-viewed-box {
  background: #fff;
  padding: 60px 0 60px 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-recently-viewed-area .binduz-er-recently-viewed-box {
    padding: 30px 0 30px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-recently-viewed-area .binduz-er-recently-viewed-box {
    padding: 0px 30px 0px 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-recently-viewed-area .binduz-er-recently-viewed-box {
    padding: 0px 30px 0px 30px;
  }
}

.binduz-er-newsletter-box {
  padding: 72px 65px;
  background: #004e87;
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-newsletter-box {
    padding: 40px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-newsletter-box {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-newsletter-box {
    margin-bottom: 30px;
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-newsletter-box {
    margin-bottom: 30px;
    padding: 72px 65px;
  }
}
.binduz-er-newsletter-box::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/newsletter-news.jpg);
  z-index: -1;
  opacity: 0.1;
}
.binduz-er-newsletter-box .binduz-er-title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  padding-top: 22px;
  margin-bottom: 4px;
}
.binduz-er-newsletter-box p {
  color: #a1bbce;
  font-size: 14px;
  margin-bottom: 24px;
}
.binduz-er-newsletter-box .binduz-er-input-box {
  position: relative;
}
.binduz-er-newsletter-box .binduz-er-input-box button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background-color: transparent;
  color: #fff;
  line-height: 60px;
  padding: 0 30px;
  font-size: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-newsletter-box .binduz-er-input-box button {
    right: -1px;
  }
}
.binduz-er-newsletter-box .binduz-er-input-box input {
  width: 92%;
  height: 58px;
  padding: 0;
  background: #0c568c;
  color: #fff;
  border: 0;
  padding-left: 30px;
}
.binduz-er-newsletter-box .binduz-er-input-box input::placeholder {
  opacity: 1;
  color: #79a2c0;
}

/*===========================
    14.BINDUZ EDITORS PACK css 
===========================*/
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area {
  padding-top: 60px;
  padding-bottom: 30px;
  background: #000;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-title {
  border-top-color: #202020;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-title
  .binduz-er-title {
  color: #fff;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-title
  a {
  color: #f63a3a;
  font-size: 14px;
  font-weight: 500;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content {
  background: #000;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  color: #999;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content
  .binduz-er-title {
  color: #fff;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content
  .binduz-er-title
  a {
  color: #fff;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content
  .binduz-er-meta-author
  span {
  color: #555;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-editors-pack-content
  .binduz-er-meta-author
  span
  span {
  color: #fff;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item {
  border-right-color: #202020;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item {
  background: #000;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date {
  color: #999;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title
  a {
  color: #fff;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-author
  span {
  color: #535353;
}
.binduz-er-editors-pack-white-area.binduz-er-editors-pack-dark-area
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-author
  span
  span {
  color: #999;
}

.binduz-er-editors-pack-title {
  border-top: 1px solid #eaeaea;
}
.binduz-er-editors-pack-title .binduz-er-title {
  font-size: 18px;
  font-weight: 500;
  color: #f63a3a;
  margin-bottom: 33px;
  padding-top: 27px;
}

.binduz-er-editors-pack-thumb img {
  width: 100%;
}

.binduz-er-editors-pack-content {
  background: #fff;
  margin-left: -205px;
  padding: 80px 0 80px 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-editors-pack-content {
    padding: 50px 0px 50px 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-editors-pack-content {
    padding: 30px 0px 30px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-editors-pack-content {
    padding: 30px 0 0;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-editors-pack-content {
    padding: 30px 0 0;
    margin-left: 0;
  }
}
.binduz-er-editors-pack-content .binduz-er-meta-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media (max-width: 767px) {
  .binduz-er-editors-pack-content .binduz-er-meta-item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-editors-pack-content .binduz-er-meta-item {
    display: flex;
  }
}
.binduz-er-editors-pack-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #ffeaea;
  display: inline-block;
  line-height: 30px;
  padding: 0 15px;
  color: #f63a3a;
  font-weight: 700;
}
.binduz-er-editors-pack-content .binduz-er-meta-item .binduz-er-meta-date span {
  color: #777777;
  margin-left: 20px;
  display: inline-block;
}
@media (max-width: 767px) {
  .binduz-er-editors-pack-content
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-editors-pack-content
    .binduz-er-meta-item
    .binduz-er-meta-date
    span {
    margin-left: 20px;
    margin-top: 0;
  }
}
.binduz-er-editors-pack-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span
  i {
  padding-right: 6px;
}
.binduz-er-editors-pack-content .binduz-er-title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 500;
  text-transform: inherit;
  padding-top: 10px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .binduz-er-editors-pack-content .binduz-er-title {
    font-size: 23px;
    line-height: 33px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-editors-pack-content .binduz-er-title {
    font-size: 30px;
    line-height: 40px;
  }
}
.binduz-er-editors-pack-content .binduz-er-meta-author {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  padding-top: 20px;
}
@media (max-width: 767px) {
  .binduz-er-editors-pack-content .binduz-er-meta-author {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-editors-pack-content .binduz-er-meta-author {
    display: flex;
  }
}
.binduz-er-editors-pack-content .binduz-er-meta-author .binduz-er-author img {
  margin-right: 15px;
}
.binduz-er-editors-pack-content .binduz-er-meta-author .binduz-er-author span {
  color: #777777;
}
.binduz-er-editors-pack-content
  .binduz-er-meta-author
  .binduz-er-author
  span
  span {
  color: #000;
  font-weight: 700;
}
.binduz-er-editors-pack-content:hover
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}

.binduz-er-editors-pack-item {
  margin-left: -30px;
  margin-right: -30px;
}
.binduz-er-editors-pack-item .binduz-er-recently-viewed-item {
  padding-left: 30px;
  padding-right: 30px;
  border-right: 1px solid #f1f1f1;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-editors-pack-item
    .binduz-er-recently-viewed-item
    .binduz-er-content
    .binduz-er-meta-item {
    display: block;
  }
}
.binduz-er-editors-pack-item
  .binduz-er-recently-viewed-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #ffeaea;
  color: #f63a3a;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-editors-pack-item
    .binduz-er-recently-viewed-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-left: 0;
    margin-top: 10px;
  }
}
.binduz-er-editors-pack-item
  .binduz-er-recently-viewed-item
  .binduz-er-content
  .binduz-er-title {
  font-weight: 500 !important;
  margin-bottom: 0px;
}
.binduz-er-editors-pack-item
  .binduz-er-recently-viewed-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-editors-pack-item
  .binduz-er-recently-viewed-item
  .binduz-er-content
  .binduz-er-meta-author
  span {
  font-size: 14px;
  font-style: italic;
  color: #bcbcbc;
}
.binduz-er-editors-pack-item
  .binduz-er-recently-viewed-item
  .binduz-er-content
  .binduz-er-meta-author
  span
  span {
  font-style: normal;
  color: #777;
  padding-left: 4px;
}
.binduz-er-editors-pack-item
  .binduz-er-recently-viewed-item:hover
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}
.binduz-er-editors-pack-item
  .col-lg-3:last-child
  .binduz-er-recently-viewed-item,
.binduz-er-editors-pack-item
  .col-lg-4:last-child
  .binduz-er-recently-viewed-item {
  border-right: 0;
}

/*===========================
    15.BINDUZ POPULER NEWS css 
===========================*/
.binduz-er-popular-news-title {
  border-top: 1px solid #f2f2f2;
  padding-top: 28px;
  padding-bottom: 20px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .binduz-er-popular-news-title {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-popular-news-title {
    display: flex;
  }
}
.binduz-er-popular-news-title .binduz-er-title {
  color: #f63a3a;
  font-size: 18px;
  font-weight: 500;
}
.binduz-er-popular-news-title ul li.nav-item {
  margin-left: 32px;
}
@media (max-width: 767px) {
  .binduz-er-popular-news-title ul li.nav-item {
    margin-left: 0;
    margin-right: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-popular-news-title ul li.nav-item {
    margin-left: 20;
    margin-right: 0px;
  }
}
.binduz-er-popular-news-title ul li.nav-item a {
  padding: 0;
  color: #999;
}
.binduz-er-popular-news-title ul li.nav-item a.active {
  color: #000;
  background-color: transparent;
}

.binduz-er-popular-news-items
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-thumb {
  margin-bottom: 30px;
}
.binduz-er-popular-news-items
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #ffeaea;
  color: #f63a3a;
}
.binduz-er-popular-news-items
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-popular-news-items
    .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-popular-news-items
    .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (max-width: 767px) {
  .binduz-er-popular-news-items
    .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 20px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-popular-news-items
    .binduz-er-video-post
    .binduz-er-latest-news-item
    .binduz-er-content
    .binduz-er-title {
    font-size: 30px;
    line-height: 40px;
  }
}
.binduz-er-popular-news-items
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #f63a3a;
}
.binduz-er-popular-news-items
  .binduz-er-video-post
  .binduz-er-latest-news-item:hover
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #f63a3a;
  color: #fff;
}

.binduz-er-popular-news-items {
  margin-left: -30px;
  margin-right: -30px;
}
.binduz-er-popular-news-items .binduz-er-video-post {
  padding-left: 30px;
  padding-right: 30px;
  border-right: 1px solid #eeeeee;
}
.binduz-er-popular-news-items .col-lg-6:last-child .binduz-er-video-post {
  border-right: 0;
}

.binduz-er-populer-news-social .binduz-er-social-list .binduz-er-list a {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 0px;
  padding: 10px 30px 10px 10px;
  align-items: center;
  margin-bottom: 8px;
}
.binduz-er-populer-news-social .binduz-er-social-list .binduz-er-list a:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
}
.binduz-er-populer-news-social .binduz-er-social-list .binduz-er-list a span {
  font-size: 14px;
  color: #000;
}
.binduz-er-populer-news-social
  .binduz-er-social-list
  .binduz-er-list
  a
  span
  span {
  font-weight: 700;
}
.binduz-er-populer-news-social .binduz-er-social-list .binduz-er-list a span i {
  height: 40px;
  width: 40px;
  background: #3b5999;
  text-align: center;
  line-height: 40px;
  color: #fff;
  border-radius: 0px;
  margin-right: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-populer-news-social
    .binduz-er-social-list
    .binduz-er-list
    a
    span
    i {
    margin-right: 5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-populer-news-social
    .binduz-er-social-list
    .binduz-er-list
    a
    span
    i {
    margin-right: 5px;
  }
}
.binduz-er-populer-news-social
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(2)
  span
  i {
  background: #55acee;
}
.binduz-er-populer-news-social
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(3)
  span
  i {
  background: #0057ff;
}
.binduz-er-populer-news-social
  .binduz-er-social-list
  .binduz-er-list
  a:nth-child(4)
  span
  i {
  background: #cd201f;
}
.binduz-er-populer-news-social.binduz-er-author-page-social
  .binduz-er-social-list
  .binduz-er-list
  a {
  background: #fff;
}

.binduz-er-populer-news-sidebar-post .binduz-er-popular-news-title {
  padding-top: 12px;
  padding-bottom: 15px;
}
.binduz-er-populer-news-sidebar-post
  .binduz-er-popular-news-title
  ul
  li.nav-item {
  margin-left: 0;
  margin-right: 30px;
}
.binduz-er-populer-news-sidebar-post
  .binduz-er-popular-news-title
  ul
  li.nav-item
  a {
  color: #dedede;
}
.binduz-er-populer-news-sidebar-post
  .binduz-er-popular-news-title
  ul
  li.nav-item
  a.active {
  color: #f63a3a;
}
.binduz-er-populer-news-sidebar-post .binduz-er-sidebar-latest-post-box {
  margin-top: 0;
}
.binduz-er-populer-news-sidebar-post
  .binduz-er-sidebar-latest-post-box
  .binduz-er-sidebar-latest-post-item
  .binduz-er-thumb {
  border-radius: 0;
}

.binduz-er-populer-news-sidebar-faq .binduz-er-top-news-faq {
  background: #000;
  position: relative;
  z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-populer-news-sidebar-faq .binduz-er-top-news-faq {
    padding: 20px;
  }
}
.binduz-er-populer-news-sidebar-faq .binduz-er-top-news-faq::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(../images/faq-bg.jpg);
  opacity: 0.2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.binduz-er-populer-news-sidebar-faq .binduz-er-top-news-faq .binduz-er-title {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -1px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-populer-news-sidebar-faq .binduz-er-top-news-faq .binduz-er-title {
    font-size: 17px;
    line-height: 27px;
  }
}
.binduz-er-populer-news-sidebar-faq
  .binduz-er-top-news-faq
  .binduz-er-meta-author
  span
  span {
  color: #fff;
}
.binduz-er-populer-news-sidebar-faq .binduz-er-top-news-faq .binduz-er-answare {
  width: 100%;
}

.binduz-er-populer-news-sidebar-newsletter .binduz-er-newsletter-box {
  padding: 40px;
  background: #f63a3a;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-populer-news-sidebar-newsletter .binduz-er-newsletter-box {
    padding: 10px;
  }
}
.binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-title {
  font-size: 24px;
  font-weight: 500;
}
.binduz-er-populer-news-sidebar-newsletter .binduz-er-newsletter-box p {
  color: rgba(255, 255, 255, 0.7);
}
.binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-input-box
  input {
  background: #fff;
  color: #999;
}
.binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-input-box
  input::placeholder {
  color: #999;
  opacity: 1;
}
.binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-input-box
  button {
  color: #f63a3a;
  color: #999;
}
.binduz-er-populer-news-sidebar-newsletter.binduz-er-author-page-newsletter
  .binduz-er-newsletter-box {
  padding: 70px 40px;
  background: #e74d58;
}

.binduz-er-populer-news-sidebar-add .binduz-er-sidebar-add {
  background-image: url(../images/space-bg-5.jpg);
}

/*===========================
    16.BINDUZ ABOUT US css 
===========================*/
.binduz-er-about-us-content {
  padding-top: 55px;
}
.binduz-er-about-us-content .binduz-er-title {
  font-size: 20px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 18px;
}
.binduz-er-about-us-content p {
  font-size: 18px;
  line-height: 28px;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .binduz-er-about-us-content p {
    padding-right: 0;
  }
}

.binduz-er-about-us-box {
  text-align: center;
  background: #fff;
  border: 2px solid #d3d3d3;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.04);
  padding: 60px;
  margin-top: 35px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-about-us-box {
    padding: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-about-us-box {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-about-us-box {
    padding: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-about-us-box {
    padding: 60px;
  }
}
.binduz-er-about-us-box icon {
  display: inline-block;
}
.binduz-er-about-us-box .binduz-er-title {
  font-size: 20px;
  padding-top: 35px;
  margin-bottom: 18px;
}
.binduz-er-about-us-box:hover {
  box-shadow: none;
  border-color: #fff;
}

.binduz-er-about-play-thumb {
  position: relative;
}
.binduz-er-about-play-thumb img {
  width: 100%;
}
.binduz-er-about-play-thumb .binduz-er-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.binduz-er-about-play-thumb .binduz-er-play a {
  height: 120px;
  width: 120px;
  display: inline-block;
  background: #fff;
  text-align: center;
  line-height: 120px;
  border-radius: 50%;
  color: #e74d58;
  font-size: 20px;
}
@media (max-width: 767px) {
  .binduz-er-about-play-thumb .binduz-er-play a {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 16px;
  }
}

.binduz-er-about-play-content {
  padding-left: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-about-play-content {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-about-play-content {
    padding-left: 0;
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .binduz-er-about-play-content {
    padding-left: 0;
    margin-top: 40px;
  }
}
.binduz-er-about-play-content .binduz-er-title {
  font-size: 48px;
  margin-bottom: 38px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-about-play-content .binduz-er-title {
    font-size: 38px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-about-play-content .binduz-er-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-about-play-content .binduz-er-title {
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-about-play-content .binduz-er-title {
    font-size: 36px;
  }
}
.binduz-er-about-play-content ul li a {
  border-radius: 0 !important;
  color: #afafaf;
  background: #fff;
  padding: 0 45px;
  line-height: 60px;
  margin-right: 20px;
  font-weight: 700;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-about-play-content ul li a {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-about-play-content ul li a {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .binduz-er-about-play-content ul li a {
    padding: 0 15px;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-about-play-content ul li a {
    padding: 0 30px;
    margin-bottom: 0;
  }
}
.binduz-er-about-play-content ul li a.active {
  background-color: #e74d58 !important;
  color: #fff;
}
.binduz-er-about-play-content .tab-content .binduz-er-text {
  padding-top: 7px;
  font-size: 18px;
  line-height: 28px;
}

/*===========================
    17.BINDUZ CONTACT US css 
===========================*/
.binduz-er-contact-us-area {
  padding-bottom: 60px;
}

.binduz-er-map-area .binduz-er-map-box iframe {
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-map-area .binduz-er-map-box iframe {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .binduz-er-map-area .binduz-er-map-box iframe {
    height: 300px;
  }
}

.binduz-er-contact-us-box {
  margin-left: 50px;
  margin-right: 50px;
  background: #fff;
  padding: 54px 60px 60px;
  margin-top: -87px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-contact-us-box {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-contact-us-box {
    margin-left: 0px;
    margin-right: 0px;
    padding: 20px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-contact-us-box {
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px;
    margin-top: -87px;
  }
}
.binduz-er-contact-us-box form {
  position: relative;
}
.binduz-er-contact-us-box form button {
  position: absolute;
  left: 50%;
  bottom: -90px;
  transform: translateX(-50%);
  border-radius: 0;
  background: #e74d58;
  border: 0;
  line-height: 60px;
  padding: 0 42px;
}
.binduz-er-contact-us-box form button:hover {
  background: #fff;
  color: #e74d58;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
}
.binduz-er-contact-us-box .binduz-er-contact-title .binduz-er-title {
  font-size: 20px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 25px;
}
.binduz-er-contact-us-box .binduz-er-input-box {
  margin-top: 20px;
  position: relative;
}
.binduz-er-contact-us-box .binduz-er-input-box i {
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 14px;
  color: #ababab;
}
.binduz-er-contact-us-box .binduz-er-input-box input {
  width: 100%;
  border: 2px solid #d8d8d8;
  height: 60px;
  padding-left: 30px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-contact-us-box .binduz-er-input-box input::placeholder {
  color: #ababab;
  opacity: 1;
}
.binduz-er-contact-us-box .binduz-er-input-box input:focus,
.binduz-er-contact-us-box .binduz-er-input-box input:hover {
  box-shadow: none;
}
.binduz-er-contact-us-box .binduz-er-input-box textarea {
  width: 100%;
  border: 2px solid #d8d8d8;
  height: 250px;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  resize: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-contact-us-box .binduz-er-input-box textarea::placeholder {
  color: #ababab;
  opacity: 1;
}
.binduz-er-contact-us-box .binduz-er-input-box textarea:focus,
.binduz-er-contact-us-box .binduz-er-input-box textarea:hover {
  box-shadow: none;
}
.binduz-er-contact-us-box .binduz-er-input-box .nice-select {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  line-height: 60px;
  color: #ababab;
  border: 2px solid #d8d8d8;
  border-radius: 0;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-contact-us-box .binduz-er-input-box .nice-select::after {
  display: none;
}
.binduz-er-contact-us-box .binduz-er-input-box .nice-select:focus,
.binduz-er-contact-us-box .binduz-er-input-box .nice-select:hover {
  box-shadow: none;
}
.binduz-er-contact-us-box .binduz-er-input-box .nice-select .list {
  border-radius: 0;
  width: 100%;
}

.binduz-er-contact-info-box {
  padding: 40px;
  background: #fff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-contact-info-box {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-contact-info-box {
    margin-bottom: 30px;
  }
}
.binduz-er-contact-info-box .binduz-er-title {
  font-size: 20px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 32px;
}
.binduz-er-contact-info-box ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-contact-info-box ul li {
  color: #000;
  margin-bottom: 14px;
}
.binduz-er-contact-info-box ul li span {
  color: #777777;
  padding-left: 5px;
}
.binduz-er-contact-info-box > a {
  border-radius: 0;
  background: #f8f8f8;
  color: #777777;
  border: 0;
  line-height: 60px;
  padding: 0 45px;
  margin-top: 20px;
}
.binduz-er-contact-info-box > a.active,
.binduz-er-contact-info-box > a:hover {
  background: #e44c57;
  color: #fff;
}

/*===========================
    18.BINDUZ AUTHOR USER css 
===========================*/
.binduz-er-author-box {
  background: #fff;
  padding: 40px 40px 65px;
  margin-bottom: 60px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-box {
    display: block;
    padding: 40px 40px 80px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-box {
    display: block;
    padding: 40px 20px 80px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-box {
    display: block;
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-box {
    display: block;
    padding: 40px 40px 80px;
  }
}
.binduz-er-author-box .binduz-er-thumb {
  min-width: 240px;
  margin-right: 49px;
  position: relative;
  height: 240px;
}
.binduz-er-author-box .binduz-er-thumb img {
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-box .binduz-er-thumb {
    min-width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-box .binduz-er-thumb {
    min-width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
}
.binduz-er-author-box .binduz-er-thumb span {
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translateX(-50%);
  display: inline-block;
  line-height: 50px;
  padding: 0 32px;
  background: #fff;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
}
.binduz-er-author-box .binduz-er-content {
  padding-right: 30px;
  padding-top: 14px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-author-box .binduz-er-content {
    padding-top: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-author-box .binduz-er-content {
    padding-top: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-box .binduz-er-content {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-box .binduz-er-content {
    padding-right: 0;
  }
}
.binduz-er-author-box .binduz-er-content p {
  font-size: 24px;
  line-height: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-author-box .binduz-er-content p {
    font-size: 20px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-box .binduz-er-content p {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-box .binduz-er-content p {
    font-size: 20px;
    line-height: 30px;
  }
}
.binduz-er-author-box .binduz-er-content .binduz-er-meta-author span {
  font-weight: 700;
  color: #000;
}
.binduz-er-author-box .binduz-er-content .binduz-er-meta-author span span {
  font-weight: 400;
  color: #e74d58;
}
.binduz-er-author-box .binduz-er-author-contact {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-box .binduz-er-author-contact {
    right: auto;
    left: 40px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-box .binduz-er-author-contact {
    right: auto;
    left: 40px;
    display: none !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-box .binduz-er-author-contact {
    right: auto;
    left: 40px;
    display: flex !important;
  }
}
.binduz-er-author-box .binduz-er-author-contact ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-author-box .binduz-er-author-contact ul li {
  display: inline-block;
}
.binduz-er-author-box .binduz-er-author-contact ul li a {
  display: inline-block;
  height: 60px;
  width: 60px;
  background: #efefef;
  color: #777777;
  text-align: center;
  line-height: 60px;
  margin-right: 4px;
  font-size: 18px;
}
.binduz-er-author-box .binduz-er-author-contact ul li:nth-child(2):hover a {
  background: #55acee;
}
.binduz-er-author-box .binduz-er-author-contact ul li:nth-child(3):hover a {
  background: #0056ff;
}
.binduz-er-author-box .binduz-er-author-contact ul li:nth-child(4):hover a {
  background: #cd201f;
}
.binduz-er-author-box .binduz-er-author-contact ul li:hover a {
  background: #3b5999;
  color: #fff;
}
.binduz-er-author-box .binduz-er-author-contact .binduz-er-contact-link a {
  display: inline-block;
  line-height: 60px;
  background: #e74d58;
  color: #fff;
  padding: 0 40px;
  margin-left: 4px;
}

.binduz-er-author-sidebar-search-bar .binduz-er-input-box {
  position: relative;
}
.binduz-er-author-sidebar-search-bar .binduz-er-input-box button {
  position: absolute;
  right: 0;
  top: 0;
  background: #e74d58;
  height: 62px;
  width: 60px;
  border: 0;
  color: #fff;
  font-size: 18px;
}
.binduz-er-author-sidebar-search-bar .binduz-er-input-box input {
  width: 100%;
  border: 0;
  background: #fff;
  line-height: 60px;
  padding-left: 30px;
  color: #000;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
}
.binduz-er-author-sidebar-search-bar .binduz-er-input-box input::placeholder {
  opacity: 1;
  color: #d2d2d2;
  font-style: italic;
}

.binduz-er-populer-news-social
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item {
  background-color: transparent;
}
.binduz-er-populer-news-social
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-thumb
  .binduz-er-play {
  position: absolute;
  left: 1px;
  bottom: 1px;
  top: auto;
}
.binduz-er-populer-news-social
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-thumb
  .binduz-er-play
  a {
  border-radius: 0;
  background: #f8f8f8;
  color: #e74d58;
}
.binduz-er-populer-news-social
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-categories
  a {
  background: #ffe7e9;
  color: #e74d58;
}
.binduz-er-populer-news-social
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title
  a:hover {
  color: #e74d58;
}
.binduz-er-populer-news-social
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-author
  span {
  color: #c7c7c7;
}
.binduz-er-populer-news-social
  .binduz-er-recently-viewed-item
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-author
  span
  span {
  color: #777777;
}

.binduz-er-author-item {
  background: #fff;
  margin-right: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-author-item {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-item {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-item {
    margin-right: 0;
  }
}
.binduz-er-author-item .binduz-er-thumb img {
  width: 100%;
}
.binduz-er-author-item .binduz-er-content {
  padding: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-item .binduz-er-content {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content {
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content {
    padding: 30px;
  }
}
.binduz-er-author-item .binduz-er-content .binduz-er-meta-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-meta-item {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-meta-item {
    display: flex;
  }
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories {
  margin-right: 30px;
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories
  a {
  background: #ffe7e9;
  color: #e74d58;
  display: inline-block;
  line-height: 30px;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .binduz-er-author-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-top: 10px;
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-item
    .binduz-er-content
    .binduz-er-meta-item
    .binduz-er-meta-date {
    margin-top: 0px;
    margin-left: 10px;
  }
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span {
  font-size: 14px;
  color: #777777;
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date
  span
  i {
  padding-right: 5px;
}
.binduz-er-author-item .binduz-er-content .binduz-er-title {
  font-size: 46px;
  line-height: 58px;
  letter-spacing: -1px;
  margin-top: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-title {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-title {
    font-size: 32px;
    line-height: 42px;
  }
}
.binduz-er-author-item .binduz-er-content .binduz-er-title a {
  color: #000;
}
.binduz-er-author-item .binduz-er-content .binduz-er-title a:hover {
  color: #f63a3a;
}
.binduz-er-author-item .binduz-er-content .binduz-er-meta-author {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  padding-top: 20px;
}
@media (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-meta-author {
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-item .binduz-er-content .binduz-er-meta-author {
    display: flex;
  }
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-author
  .binduz-er-author
  img {
  margin-right: 15px;
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-author
  .binduz-er-author
  span {
  color: #777777;
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-author
  .binduz-er-author
  span
  span {
  color: #000;
  font-weight: 700;
}
@media (max-width: 767px) {
  .binduz-er-author-item
    .binduz-er-content
    .binduz-er-meta-author
    .binduz-er-meta-list {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-item
    .binduz-er-content
    .binduz-er-meta-author
    .binduz-er-meta-list {
    margin-top: 0px;
  }
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-author
  .binduz-er-meta-list
  ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-author-item
  .binduz-er-content
  .binduz-er-meta-author
  .binduz-er-meta-list
  ul
  li {
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  line-height: 14px;
  border-left: 1px solid #dddddd;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-author-item
    .binduz-er-content
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    margin-left: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .binduz-er-author-item
    .binduz-er-content
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    border-left: 0;
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-author-item
    .binduz-er-content
    .binduz-er-meta-author
    .binduz-er-meta-list
    ul
    li {
    border-left: 1px solid #dddddd;
    margin-left: 15px;
  }
}

.binduz-er-archived-sidebar-about {
  background: #fff;
  text-align: center;
  margin-bottom: 40px;
  padding: 50px 0 0;
}
.binduz-er-archived-sidebar-about .binduz-er-user {
  height: 140px;
  width: 140px;
  display: inline-block;
  position: relative;
}
.binduz-er-archived-sidebar-about .binduz-er-user .binduz-er-icon {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08);
  background: #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  color: #f63a3a;
}
.binduz-er-archived-sidebar-about span {
  font-size: 14px;
  text-transform: capitalize;
  padding-top: 22px;
  padding-bottom: 3px;
  display: block;
}
.binduz-er-archived-sidebar-about .binduz-er-title {
  font-size: 20px;
}
.binduz-er-archived-sidebar-about ul {
  margin: 43px 0 0;
  padding: 0;
  list-style-type: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
}
.binduz-er-archived-sidebar-about ul li {
  display: inline-block;
}
.binduz-er-archived-sidebar-about ul li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #efefef;
  color: #777777;
  text-align: center;
  line-height: 40px;
  margin: 0 4px;
}
.binduz-er-archived-sidebar-about ul li:nth-child(2):hover a {
  background: #55acee;
}
.binduz-er-archived-sidebar-about ul li:nth-child(3):hover a {
  background: #0056ff;
}
.binduz-er-archived-sidebar-about ul li:nth-child(4):hover a {
  background: #cd201f;
}
.binduz-er-archived-sidebar-about ul li:hover a {
  background: #3b5999;
  color: #fff;
}

/*===========================
    19.BINDUZ BLOG DETAILS css 
===========================*/
.binduz-er-blog-details-box {
  padding: 0px 50px 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-blog-details-box {
    padding: 0 30px 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-blog-details-box {
    padding: 0 20px 30px;
  }
}
.binduz-er-blog-details-box .binduz-er-text {
  font-size: 18px;
  line-height: 32px;
}
.binduz-er-blog-details-box .binduz-er-text .binduz-er-title {
  font-size: 20px;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 20px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-blog-details-box
    .binduz-er-text.binduz-er-blog-details-thumb-text {
    padding: 15px 0 0;
  }
}
@media (max-width: 767px) {
  .binduz-er-blog-details-box
    .binduz-er-text.binduz-er-blog-details-thumb-text {
    padding: 15px 0 0;
  }
}
.binduz-er-blog-details-box .binduz-er-text p {
  margin: 0 0 25px;
}
.binduz-er-blog-details-box .binduz-er-text ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-blog-details-box .binduz-er-text ul li {
  font-size: 18px;
  margin-bottom: 8px;
  position: relative;
  padding-left: 26px;
}
.binduz-er-blog-details-box .binduz-er-text ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 13px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #dedede;
}
.binduz-er-blog-details-box .binduz-er-quote-text {
  box-shadow: 0px 16px 32px 10px rgba(0, 0, 0, 0.04);
  padding: 41px 50px 45px;
  margin-top: 55px;
  position: relative;
  overflow: hidden;
}
.binduz-er-blog-details-box .binduz-er-quote-text::before {
  position: absolute;
  content: "";
  right: -35px;
  bottom: -25px;
  height: 134px;
  width: 155px;
  background-image: url(../images/icon/quote-bg.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-blog-details-box .binduz-er-quote-text {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-blog-details-box .binduz-er-quote-text {
    padding: 20px;
  }
}
.binduz-er-blog-details-box .binduz-er-quote-text p {
  font-weight: 700;
  color: #000;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 9px;
}
@media (max-width: 767px) {
  .binduz-er-blog-details-box .binduz-er-quote-text p {
    font-size: 17px;
    line-height: 27px;
  }
}
.binduz-er-blog-details-box .binduz-er-quote-text span {
  color: #bfbfbf;
  font-style: italic;
}
.binduz-er-blog-details-box .binduz-er-quote-text span span {
  font-style: normal;
  color: #f63a3a;
  padding-left: 4px;
}

.binduz-er-blog-details-thumb-play {
  position: relative;
}
.binduz-er-blog-details-thumb-play .binduz-er-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.binduz-er-blog-details-thumb-play .binduz-er-play a {
  height: 120px;
  width: 120px;
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 120px;
  color: #e74d58;
  font-size: 20px;
}
@media (max-width: 767px) {
  .binduz-er-blog-details-thumb-play .binduz-er-play a {
    height: 80px;
    width: 80px;
    line-height: 80px;
    font-size: 16px;
  }
}
.binduz-er-blog-details-thumb-play img {
  width: 100%;
}

.binduz-er-social-share-tag {
  border-top: 1px solid #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 53px;
}
.binduz-er-social-share-tag .binduz-er-tag ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-social-share-tag .binduz-er-tag ul li {
  display: inline-block;
}
.binduz-er-social-share-tag .binduz-er-tag ul li a {
  background: #f4f4f4;
  color: #777777;
  display: inline-block;
  line-height: 30px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 500;
}
.binduz-er-social-share-tag .binduz-er-tag ul li a:hover {
  background: #e74d58;
  color: #fff;
}
.binduz-er-social-share-tag .binduz-er-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.binduz-er-social-share-tag .binduz-er-social ul li {
  display: inline-block;
}
.binduz-er-social-share-tag .binduz-er-social ul li a {
  display: inline-block;
  color: #d3d3d3;
  font-size: 18px;
  margin-left: 24px;
}
@media (max-width: 767px) {
  .binduz-er-social-share-tag .binduz-er-social ul li a {
    margin-left: 0;
    margin-right: 20px;
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-social-share-tag .binduz-er-social ul li a {
    margin-left: 20px;
    margin-right: 0px;
    margin-top: 0;
  }
}
.binduz-er-social-share-tag .binduz-er-social ul li a:hover {
  color: #e74d58;
}

.binduz-er-blog-post-prev-next {
  padding-top: 20px;
  padding-bottom: 13px;
  position: relative;
  border-bottom: 1px solid #f7f7f7;
}
.binduz-er-blog-post-prev-next .binduz-er-post-bars {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .binduz-er-blog-post-prev-next .binduz-er-post-bars {
    display: none;
  }
}
.binduz-er-blog-post-prev-next .binduz-er-post-prev-next {
  width: 40%;
}
.binduz-er-blog-post-prev-next .binduz-er-post-prev-next span {
  font-size: 14px;
}
.binduz-er-blog-post-prev-next .binduz-er-post-prev-next .binduz-er-title {
  font-size: 16px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.binduz-er-blog-related-post {
  padding-top: 50px;
}
.binduz-er-blog-related-post .binduz-er-related-post-title .binduz-er-title {
  font-size: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 19px;
  margin-bottom: 30px;
}

.binduz-er-blog-related-post-slide {
  margin-right: -80px;
}
@media (max-width: 767px) {
  .binduz-er-blog-related-post-slide {
    margin-right: 0;
  }
}
.binduz-er-blog-related-post-slide .binduz-er-video-post {
  margin-right: 30px;
}
@media (max-width: 767px) {
  .binduz-er-blog-related-post-slide .binduz-er-video-post {
    margin-right: 0;
  }
}
.binduz-er-blog-related-post-slide
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content {
  padding: 0;
}
.binduz-er-blog-related-post-slide
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-categories {
  margin-right: 20px;
}
.binduz-er-blog-related-post-slide
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-meta-item
  .binduz-er-meta-date {
  margin-left: 0;
}
.binduz-er-blog-related-post-slide
  .binduz-er-video-post
  .binduz-er-latest-news-item
  .binduz-er-content
  .binduz-er-title {
  font-size: 20px;
  letter-spacing: 0;
}

.binduz-er-blog-post-form {
  padding-top: 18px;
}
.binduz-er-blog-post-form .binduz-er-blog-post-title .binduz-er-title {
  font-size: 20px;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.binduz-er-blog-post-form .binduz-er-input-box {
  margin-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}
.binduz-er-blog-post-form .binduz-er-input-box i {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 14px;
  color: #ababab;
}
.binduz-er-blog-post-form .binduz-er-input-box input {
  width: 100%;
  border: 2px solid #d8d8d8;
  height: 70px;
  padding-left: 30px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-blog-post-form .binduz-er-input-box input::placeholder {
  color: #ababab;
  opacity: 1;
}
.binduz-er-blog-post-form .binduz-er-input-box input:focus,
.binduz-er-blog-post-form .binduz-er-input-box input:hover {
  box-shadow: none;
}
.binduz-er-blog-post-form .binduz-er-input-box textarea {
  width: 100%;
  border: 2px solid #d8d8d8;
  height: 250px;
  padding-left: 30px;
  padding-top: 20px;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  resize: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.binduz-er-blog-post-form .binduz-er-input-box textarea::placeholder {
  color: #ababab;
  opacity: 1;
}
.binduz-er-blog-post-form .binduz-er-input-box textarea:focus,
.binduz-er-blog-post-form .binduz-er-input-box textarea:hover {
  box-shadow: none;
}
.binduz-er-blog-post-form .binduz-er-input-box .nice-select {
  width: 100%;
  height: 70px;
  padding: 0 30px;
  line-height: 70px;
  color: #ababab;
  border: 2px solid #d8d8d8;
  border-radius: 0;
  box-shadow: 0 32px 32px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-blog-post-form .binduz-er-input-box .nice-select {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .binduz-er-blog-post-form .binduz-er-input-box .nice-select {
    margin-bottom: 20px;
  }
}
.binduz-er-blog-post-form .binduz-er-input-box .nice-select::after {
  display: none;
}
.binduz-er-blog-post-form .binduz-er-input-box .nice-select:focus,
.binduz-er-blog-post-form .binduz-er-input-box .nice-select:hover {
  box-shadow: none;
}
.binduz-er-blog-post-form .binduz-er-input-box .nice-select .list {
  border-radius: 0;
  width: 100%;
}
.binduz-er-blog-post-form .binduz-er-input-box button {
  border-radius: 0;
  padding: 0 35px;
  line-height: 60px;
  background: #e74d58;
  color: #fff;
  border: 1px solid #e74d58;
}
.binduz-er-blog-post-form .binduz-er-input-box button:hover {
  background-color: transparent;
  color: #e74d58;
}

.binduz-er-blog-bg-area {
  height: 500px;
  background-image: url(../images/blog-details-bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.binduz-er-author-item-area.binduz-er-author-item-layout-1
  .binduz-er-author-item {
  margin-top: -90px;
}

.binduz-er-blog-project-item {
  position: relative;
}
@media (max-width: 767px) {
  .binduz-er-blog-project-item {
    margin-bottom: 30px;
  }
}
.binduz-er-blog-project-item img {
  width: 100%;
}
.binduz-er-blog-project-item .binduz-er-blog-project-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 75%
  );
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.binduz-er-blog-project-item .binduz-er-blog-project-overlay a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 26px;
}

.binduz-er-blog-post-newsletter {
  padding-bottom: 35px;
}
@media (max-width: 767px) {
  .binduz-er-blog-post-newsletter {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-blog-post-newsletter {
    display: block;
  }
}
.binduz-er-blog-post-newsletter
  .binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box {
  padding: 60px 110px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-blog-post-newsletter
    .binduz-er-populer-news-sidebar-newsletter
    .binduz-er-newsletter-box {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-blog-post-newsletter
    .binduz-er-populer-news-sidebar-newsletter
    .binduz-er-newsletter-box {
    padding: 20px;
  }
}
.binduz-er-blog-post-newsletter
  .binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box::before {
  display: none;
}
.binduz-er-blog-post-newsletter
  .binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding-top: 35px;
  margin-bottom: 0;
}
.binduz-er-blog-post-newsletter
  .binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-title {
  padding-top: 6px;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 25px;
}
.binduz-er-blog-post-newsletter
  .binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-input-box
  button {
  background: #ea5f69;
  border-left: 10px solid #e74d58;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
@media (max-width: 767px) {
  .binduz-er-blog-post-newsletter
    .binduz-er-populer-news-sidebar-newsletter
    .binduz-er-newsletter-box
    .binduz-er-input-box
    button {
    position: static;
    border-left: 0;
    transform: translateY(0%);
    margin-top: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-blog-post-newsletter
    .binduz-er-populer-news-sidebar-newsletter
    .binduz-er-newsletter-box
    .binduz-er-input-box
    button {
    position: absolute;
    border-left: 10px solid;
    transform: translateY(-50%);
    margin-top: 00px;
  }
}
.binduz-er-blog-post-newsletter
  .binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-input-box
  input {
  font-style: italic;
  color: #777777;
}
.binduz-er-blog-post-newsletter
  .binduz-er-populer-news-sidebar-newsletter
  .binduz-er-newsletter-box
  .binduz-er-input-box
  input::placeholder {
  opacity: 1;
  color: #777777;
}

.binduz-er-blog-social-share {
  padding-left: 50px;
  margin-right: -50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-blog-social-share {
    display: flex;
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .binduz-er-blog-social-share {
    display: block;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-blog-social-share {
    display: flex;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .binduz-er-blog-social-share .binduz-er-item {
    width: 20%;
  }
}
@media (max-width: 767px) {
  .binduz-er-blog-social-share .binduz-er-item {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .binduz-er-blog-social-share .binduz-er-item {
    width: 25%;
  }
}
.binduz-er-blog-social-share .binduz-er-item a {
  display: block;
}
.binduz-er-blog-social-share .binduz-er-item a i {
  background: #3b5999;
  color: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-blog-social-share .binduz-er-item a i {
    float: none;
  }
}
.binduz-er-blog-social-share .binduz-er-item a span {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-top: -10px;
  display: inline-block;
  line-height: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .binduz-er-blog-social-share .binduz-er-item a span {
    display: block;
    margin-top: 10px;
  }
}
.binduz-er-blog-social-share .binduz-er-item a p {
  color: #777777;
  margin-bottom: 0;
}
.binduz-er-blog-social-share .binduz-er-item:nth-child(2) a i {
  background: #55acee;
}
.binduz-er-blog-social-share .binduz-er-item:nth-child(3) a i {
  background: #cd201f;
}

/*# sourceMappingURL=style.css.map */
