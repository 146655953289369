html {
  scroll-behavior: auto;
}
.binduz-er-hero-news-portal {
  display: block;
}
.binduz-er-hero-news-portal
  .binduz-er-news-portal-item
  .binduz-er-news-portal-item-wrapped {
  display: flex;
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item:last-child {
  margin-left: 0px;
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item:last-child {
  padding-left: 0;
}
.binduz-formated-date {
  margin-left: 4px;
}
/* line clumbs */
.line-clump-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.line-clump-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.line-clump-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.binduz-er-trending-box .binduz-er-title .binduz-er-title::before {
  width: 260px;
}
.binduz-er-featured-slider-item {
  position: relative;
}
.binduz-er-main-posts-area .binduz-er-play {
  display: none;
}

.binduz-er-news-slider-item {
  position: relative;
}
.binduz-er-news-viewed-most-slide {
  position: relative;
}
.binduz-er-recently-viewed-box .binduz-er-play {
  display: none;
}
.binduz-er-news-slider-2-item .binduz-er-news-viewed-most {
  margin: 0 0;
  padding: 0 2px;
}
.binduz-er-featured-slider-2 {
  position: relative;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang {
  background: #e74d58;
  border: 0;
  border-radius: 0;
  font-weight: 500;
  text-transform: capitalize;
  height: 60px;
  width: 140px;
  text-align: center !important;
  padding: 0;
  padding-top: 10px;
  margin-right: 50px;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang button {
  color: #fff;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-wrapper {
  display: flex;
}
.binduz-er-topbar-headline {
  position: relative;
}
.binduz-er-top-news-2-slider {
  position: relative;
}
.home-four-binduz-popular-post .binduz-er-thumb {
  width: 100%;
  height: 100%;
}
.social-news-slider-gap {
  padding: 0 15px;
}
.binduz-er-social-news-box .binduz-er-social-news-slide .col-lg-4 {
  margin-left: 0px;
  margin-right: 0px;
}
.binduz-er-social-news-slide {
  position: relative;
}
.binduz-er-author-item {
  overflow: hidden;
}
.binduz-er-video-post.binduz-er-recently-viewed-item {
  padding: 0 10px;
}
.my-select-box.mexuvo-binduz-header-dropdown .my-select-box-btn {
  background: none;
  border: none;
  font-size: 14px;
}
.my-select-box.mexuvo-binduz-tech-dropdown .my-select-box-btn {
  background: none;
  width: 100%;
  border: 2px solid #d8d8d8;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgb(0 0 0 / 4%);
}
.binduz-er-blog-post-form .binduz-er-input-box button.my-select-box-btn:hover {
  background-color: transparent;
  color: #ababab;
}

.binduz-er-top-header-area-4 .binduz-er-top-header-lang {
  padding-top: 0;
}
.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.binduz-er-news-menu-item-has-children {
  transition: all 0.5s ease-out;
}
.binduz-er-news-menu-item-has-children ul {
  transition: all 0.5s ease-in;
}
.binduz-er-contact-us-area .binduz-er-input-box.binduz-er-select-item {
  margin: -10px 0 0 0;
}
.binduz-er-contact-us-area .binduz-er-input-box.binduz-er-select-item button {
  border-radius: 0;
}
.binduz-er-featured-slider-item {
  position: relative;
}
.home-ten-hero .binduz-er-editors-pack-title {
  display: none;
}
.binduz-er-video-post.binduz-er-recently-viewed-item {
  padding: 30px;
}
